import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import AssActions from '../../../actions/assessments';
import './ass-components.css';
import { i18Get } from '../../../i18/index';

class Options extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.ansChange = this.ansChange.bind(this);
        this.ansBlur = this.ansBlur.bind(this);
    }
    ansChange(event) {
        this.props.dispatch(AssActions.ass.markAnswer({
            question: this.question.uuid,
            answer: event.target.value,
        }));
    }
    ansBlur(event) {
        this.props.dispatch(AssActions.ass.markAnswer({
            question: this.question.uuid,
            answer: event.target.value,
        }));
        var ans = _.find(this.props.assessments.selectedAssQA, { uuid: this.question.uuid });
        this.props.dispatch(AssActions.ass.documentQuestion(_.find(this.props.assessments.assList, 'selected').uuid, [ans.answer]));
    }
    render() {
        const selectedAss = _.find(this.props.assessments.assList, 'selected');
        this.question = _.find(this.props.assessments.selectedAssQA, { uuid: this.props.question });
        return (
            <textarea
                disabled={selectedAss.documented}
                className={this.question.big_text? "col md-textarea autoscroll " :"col md-textarea autoscroll sm-text-area"}
                placeholder={i18Get('Type here')}
                onChange={this.ansChange}
                onBlur={this.ansBlur}
                value={this.question.answer.textAns != null ? this.question.answer.textAns : ''}
            />
        );
    }
}
export default connect(store => ({
    assessments: store.assessments,
    translations: store.translations,
}))(Options);
