import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import 'react-table/react-table.css';
import 'react-rangeslider/lib/index.css';

import Router from './router';
import store from './store';
import NetworkService from './middlewares/network';

NetworkService.setupInterceptors(store);

render(
    <Provider store={store}>
        <Router />
    </Provider>,
    document.getElementById('root'),
);
