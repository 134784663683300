import React from 'react';
import { Input } from 'antd';

export default class InputAnt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props,
        };
    }
    render() {
        return <Input {...this.props} />;
    }
}
