import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Upload, Modal } from 'antd';
import _ from "lodash";
import axios from 'axios';
import { apiHeaders } from '../../../kado/utils/';
import './ass-components.css';
import AssActions from '../../../actions/assessments';
import { Button, Spinner } from '../../../kado/components';
import { i18Get } from '../../../i18';
import Config from "../../../cankado-config"
import getToken from '../../../actions/files/get-token';
import { ConfirmBox } from '../../../kado/utils/confirm-box';

class File extends Component {
    
    state = { 
        token: this.props.user.isAuthenticated,
        files: [],
        fileLists: [],
        fileAnswers: [],
        delStart: false,
        loader: false,
        uuid: this.props.question,
        questionnaire:"",
        assessment: ""
     }

     componentWillMount(){

         var assessment = _.find(this.props.assessments.assList, 'selected').uuid
        var ans = _.find(this.props.assessments.selectedAssQA, { uuid: this.props.question });
        if (typeof ans.answer.file_answers !== 'undefined' && ans.answer.file_answers.length > 0) {
      
            this.setState({
                fileAnswers: ans.answer.file_answers,
                questionnaire: ans.questionnaire.uuid,
                assessment: assessment
            });
        }else{
            this.setState({
                questionnaire: ans.questionnaire.uuid,
                assessment: assessment
            });
        }
        
     }
     UNSAFE_componentWillReceiveProps(nextProps){

            var ans = _.find(nextProps.assessments.selectedAssQA, { uuid: this.state.uuid });
            if (typeof ans.answer.file_answers !== 'undefined' && ans.answer.file_answers.length > 0) {
             if(this.state.fileAnswers.length !== ans.answer.file_answers.length){
                    this.setState({
                        fileAnswers: ans.answer.file_answers
                    });
                }
             }
     
     }

     ansFileChange = (fileList, answers ) => {

        let completeFileList = fileList;
        if(!this.state.delStart) {
           this.setState({
            loader: true,
            
           });
        }
        setTimeout(() => {

            const payload = {
                assessment: this.state.assessment,
                questionnaire: this.state.questionnaire,
                question: this.state.uuid,
                file_answers: [...answers, ...completeFileList],
            }

            axios.patch(`${Config.apiRoot}/patient/assessments/${payload.assessment}/answers/`, [payload], { ...apiHeaders() }).then((response) => {

                this.props.dispatch(AssActions.ass.markAnswer({
                    question: this.state.uuid,
                    answer: response.data["0"].file_answers,
                }));
                this.setState({
                    loader: false,
                    files: []
                })
            })
            
        }, 1000)
    }
     convertBase64 = (receivedFile) => {
        let tempFile = receivedFile;
        tempFile.status = 'done';
        tempFile.response = receivedFile.name;
        let FileList = this.state.fileLists;
        FileList.push(tempFile);
        this.setState({
            fileLists: FileList
        })
        const reader = new FileReader();
        let fileList = this.state.files;
        let file = {};
        reader.onload = e => {
            let base64Data = e.target.result;
            file.file_name = receivedFile.name;
            file.uuid = receivedFile.uid;
            file.uid = receivedFile.uid;
            file.file = base64Data;
            file.name = receivedFile.name;
        }
        reader.readAsDataURL(receivedFile);
        fileList.push(file);
        this.setState({
            files: fileList
        })

        this.ansFileChange(fileList, this.state.fileAnswers);
    }

     handleBeforeUpload = (receivedFile) => {
         var uuid = this.state.uuid;
         this.state.delStart = false
        this.convertBase64(receivedFile);
        return false
    }
     onRemoveFile = (receivedFile) => {
        if (this.state.files.length) {
            let fileList = this.state.files.filter(item => item.uuid !== receivedFile.uid);
            this.setState({
                fileLists: fileList
            })
            this.ansFileChange(fileList, this.state.fileAnswers);
        }
        if (this.state.fileLists.length) {
            let files = this.state.fileLists.filter(item => item.uid !== receivedFile.uid);
            this.setState({
                fileLists: files
            })
        }
    }
    getAcceptFileTypes = () => {
        return '.png,.jpg,.jpeg,.pdf,.xlsx,.xls,.docx,.doc';
    }

    DownloadFile(url){
        return (
            window.open(url+'&token='+this.state.token)
        );
    }

    onDeleteClick = ({uuid, file_name}) => {
        ConfirmBox((`${file_name}, ` +i18Get(`Are you sure that you want to delete?`) ), ()=> {
            this.state.delStart = true;
            let fileAnswer = this.state.fileAnswers;
            fileAnswer = fileAnswer.filter(file => file.uuid !== uuid);
            this.state.fileLists= [];
            this.setState({
                files: [],
                fileLists: [],
                fileAnswers: fileAnswer
            })
            this.ansFileChange(this.state.fileLists, fileAnswer);
        })
    }

    render() { 
        const selectedAss = _.find(this.props.assessments.assList, 'selected');
        return ( <div>
            {this.state.fileAnswers.length > 0 &&
                this.state.fileAnswers.map((file) => file.file_url && (
                    <div className="mt-1 mb-2 d-flex justify-content-between" m="4">
                        <div className="mr-3" title={file.file_name} >{(file.file_name).slice(0, 85)}</div>
                        <div className="questions-file-button-container d-flex">
                            <div className="questions-file-preview mx-2" onClick={() => this.DownloadFile(file.file_url)} >{i18Get("Download")}</div>
                            {!(selectedAss.documented || selectedAss.status === 'a') &&
                            <div className="questions-file-delete mx-2 text-danger" onClick={()=> this.onDeleteClick(file)}>{i18Get("Delete")}</div>
                            }
                        </div>
                    </div>
                ))
            }
            <div className={`mt-2 qstnr-file-upload  ${selectedAss.documented || selectedAss.status === 'a' ? 'non-pointer' : ''}`}>
                <Upload
                    multiple={true}
                    accept={this.getAcceptFileTypes()}
                    beforeUpload={this.handleBeforeUpload}
                    onRemove={this.onRemoveFile}
                    fileList={this.state.fileLists}
                    showUploadList={false}
                >
                    <Button   className="kado-btn">
                        {this.state.loader && <Spinner/>}{i18Get('Please click here to upload file')}
                    </Button>
                </Upload>
            </div>
        </div>  );
    }
}
export default connect(store => ({
    assessments: store.assessments,
    translations: store.translations,
    user: store.user,
}))(File);
