import { createStore, applyMiddleware } from 'redux';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
// import logger from 'redux-logger';

import { routerMiddleware } from 'react-router-redux';
import createHistory from 'history/createBrowserHistory';

import reducers from '../reducers';

export const history = createHistory();

const Store = createStore(reducers, applyMiddleware(routerMiddleware(history), promise(), thunk));

export default Store;
