import React from 'react';
import { Spin, Icon } from 'antd';
import { i18Get } from '../../../i18';

import './spinner-ant.css';

export default class SpinnerAnt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        // const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
        return (
            <div className="w-100 d-flex align-items-center justify-content-center" style={{height:'100vh'}}>
                <img className="cl-logo" alt="CANKADO" src={'/images/loading.gif'} />
            </div>
        );
    }
}
