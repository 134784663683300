import React from 'react';
import Select, { Creatable } from 'react-select';

import _ from 'lodash';
import 'react-select/dist/react-select.css';
import './style.css';
import { i18Get } from '../../../i18/index';


export default class KadoSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            multi: false,
            options: [],
            ...this.props,
            value: this.getValue(props),
        };
        this.handleChange = this.handleChange.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            ...this.state,
            ...nextProps,
            value: this.getValue(nextProps),
        });
    }
    getValue(props) {
        let value = null;
        if (!_.isUndefined(props.value)) {
            value = props.value;
        } else if (_.isArray(props.items) && props.items.length) {
            value = props.items[0].value || props.items[0].uuid || null;
        }
        return value;
    }
    handleChange(selections) {
        if (selections === null) {
            this.setState({ value: null }, () => {
                this.state.onChange(this.state.value);
            });
            return;
        }
        let newOptions = (this.state.multi) ? [...selections] : [selections];
        newOptions = _.uniqBy([...this.state.options, ...newOptions], x => x.value);
        this.setState({
            value: this.state.multi ? [selections] : selections,
            options: newOptions,
        }, () => {
            if (_.isFunction(this.state.onChange)) this.state.onChange(this.state.value);
        });
    }
    render() {
        if (this.state.creatable) {
            return (
                <Creatable
                    {...this.state}
                    onChange={this.handleChange}
                    className="form-control"
                    promptTextCreator={(v) => ([this.props.creatableLabel, v].join(' '))}
                    placeholder={i18Get('Select or Create')}
                />
            );
        }
        return (
            <Select
                {...this.state}
                onChange={this.handleChange}
                className="form-control"
                placeholder={i18Get('Select')}
            />
        );
    }
}
