import React, { Component } from 'react';
import { connect } from 'react-redux';
import CheckLoginAction from '../../actions/user/check-login';

import languageList from '../../kado/constant-files/language-options';

import TranslationActions from '../../actions/translation';

import './check-login.css';

class CheckLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.props.dispatch(CheckLoginAction());

        const languagesObject = languageList.options;
        const languageListArray = Object.keys(languagesObject).map(itm => languagesObject[itm]);
        this.props.dispatch(TranslationActions.TranslationFetch('dev'));
        languageListArray.forEach((element) => {
            const languageCode = element.value;
            this.props.dispatch(TranslationActions.TranslationFetch(languageCode));
        });
    }
    render() {
        return (
            <div className="cl-container">
                <img className="cl-logo" alt="CANKADO" src="/images/loading.gif" />
            </div>
        );
    }
}


export default connect(() => {})(CheckLogin);
