import React, { Component } from "react";
import _ from "lodash";
import { Select, InputAnt, Label } from "../../../kado/components";
import { connect } from "react-redux";
import axios from "axios";
import AssActions from "../../../actions/assessments";
import I18, { i18Get } from "../../../i18";
import Config from "../../../cankado-config";
import { apiHeaders } from "../../../kado/utils/";
import Slider from "react-rangeslider";
import {
  getTrans
} from "../../../kado/utils";

class DiagnosticType extends Component {
  state = {
    data: [],
    selfAnswered: false,
    uuid: this.props.question,
    initSet: false,
    showTable: false,
  };

  componentWillMount() {
    let assDetails = _.find(this.props.assessments.assList, "selected")
    var assessment = assDetails.uuid;
    var ans = _.find(this.props.assessments.selectedAssQA, {
      uuid: this.props.question
    });
    if (
      typeof ans.answer.diagnostic_answers !== "undefined" &&
      ans.answer.diagnostic_answers.length > 0
    ) {
      this.setState({
        data: ans.diagnostic_items,
        diagnostic_answers: ans.answer.diagnostic_answers,
        questionnaire: ans.questionnaire.uuid,
        assessment: assessment,
        assDetails: assDetails
      });
    } else {
      this.setState({
        data: ans.diagnostic_items,
        questionnaire: ans.questionnaire.uuid,
        assessment: assessment,
        assDetails: assDetails
      });
    }
  }
  getAnswers(d){
    let stData = d;
    let isAnswers =
      this.state.diagnostic_answers &&
      this.state.diagnostic_answers.length > 0 &&
      !this.state.selfAnswered
        ? true
        : false;
    for(let st of stData){

      if (!st.unitName) {
        st.unitName = st.unit_name.uuid;
      }
      if (isAnswers) {
        var tempItem = _.find(
          this.state.diagnostic_answers,
          o => o.diagnostic_item === st.uuid
        );

        if (tempItem) {
          st["value"] = tempItem.diagnostic_value;
        }else{
          st["value"] = null;
        }
      }else{
        st["value"] = null;
      }
    }
    return stData;
  }

  getReferRange(item) {
    if (
      item.ref_range_minimum === -1 ||
      item.ref_range_maximum === -1 ||
      (item.ref_range_minimum === null && item.ref_range_maximum === null)
    ) {
      return " ";
    } else {
      if (item.item_name === "Temperature (Write 371 instead of 37.1)") {
        return `${(item.ref_range_minimum / 10).toFixed()} - ${(
          item.ref_range_maximum / 10
        ).toFixed()}`;
      } else {
        return `${item.ref_range_minimum} - ${item.ref_range_maximum}`;
      }
    }
  }

  toSave(templates) {
    let diagnosticAnswers = [];

    for (let temp of templates) {
      let diagnosticAnswer = {
        diagnostic_item: temp.uuid,
        diagnostic_value: temp.value,
        unit_name: temp.unitName
      };
      diagnosticAnswers.push(diagnosticAnswer);
    }

    const payload = {
      assessment: this.state.assessment,
      questionnaire: this.state.questionnaire,
      question: this.state.uuid,
      diagnostic_answers: [...diagnosticAnswers]
    };
    axios
      .patch(
        `${Config.apiRoot}/patient/assessments/${this.state.assessment}/answers/`,
        [payload],
        { ...apiHeaders() }
      )
      .then(response => {
        this.props.dispatch(
          AssActions.ass.markAnswer({
            question: this.state.uuid,
            answer: response.data["0"].diagnostic_answers
          })
        );
      });
  }

  updateInput = e => {
    let valid = /^-?\d*[.,]?\d*$/.test(e.target.value);
    let val = e.target.value;
    if (e.target.value) {
      val = Number(e.target.value);
    }else{
      val = null;
    }
    const prevTempData = this.state.data;
    prevTempData.forEach(templateItem => {
      if (templateItem.uuid === e.target.id && valid) {
        templateItem.value = val;
      }
    });
    if (valid && val !== "") {
      this.toSave(prevTempData);
    }
    this.setState({
      ...this.state,
      data: prevTempData,
      selfAnswered: true
    });
  };

  sliderChange(value, item) {
    let prevTempData = this.state.data;
    prevTempData.forEach(templateItem => {
      if (templateItem.uuid === item.uuid) {
        templateItem.value = value;
      }
    });
    this.toSave(prevTempData);
    this.setState({
      ...this.state,
      data: prevTempData,
      selfAnswered: true
    });
  }

  loadData(d) {
    let diagData = d;
    diagData.forEach(templateItem => {
      if(!this.state.showTable){
        if(!templateItem.slider){
          this.setState({showTable : true})
        }
      }
      const dt = [];
      const obj = {
        label:
          templateItem.unit_name.unit_name === "°C (*10)"
            ? "°C"
            : templateItem.unit_name.unit_name,
        value: templateItem.unit_name.uuid,
        itemID: templateItem.uuid
      };
      if (!templateItem.unitName) {
        templateItem.unitName = obj.value;
      }
      dt.push(obj);
      templateItem.alternativeunits_set &&
        templateItem.alternativeunits_set.forEach(altUnit => {
          const obj = {
            label: altUnit.alternativeunits_set.unit_name,
            value: altUnit.alternativeunits_set.uuid,
            itemID: templateItem.uuid,
            translations: altUnit.alternative_unit.translations
          };
          dt.push(obj);
        });
      templateItem["unitOptions"] = dt;
    });
    return diagData;
  }
  unitSelect = (e, d) => {
    const prevState = this.state;
    prevState.data.forEach(templateItem => {
      if (e != null && templateItem.uuid === d.uuid) {
        templateItem.unitName = e;
        // templateItem.unitOptions.forEach(x=>{
        //   if(x.value === e){
        //     console.log("inside",templateItem, d)
        //     templateItem.unit_name = {...templateItem.unit_name, unit_name: x.label, uuid: x.value};
        //   }
        // })
      }
    });
    this.setState({
      ...prevState,
      selfAnswered: true
    });
  };
  renderSelectBoxes(x) {
    return (
      <Select
        value={x.unitName}
        clearable={false}
        options={x.unitOptions}
        onChange={e => this.unitSelect(e, x)}
      />
    );
  }
  showRows(diagData) {
    let data = [];
    _.sortBy(diagData, "order").map(item => {
      if (!item.slider) {
        data.push({
          itemname:
            item.item_name === "Temperature (Write 371 instead of 37.1)"
              ? i18Get("Temperature")
              : item.item_name,
          value: (
            <input
              style={{ height: "40px", fontSize: '1rem' }}
              value={item.value}
              id={item.uuid}
              className="py-0"
              placeholder={i18Get("Enter the value")}
              onChange={e => this.updateInput(e)}
              type="number"
            />
          ),
          unit: this.renderSelectBoxes(item),
          reference: this.getReferRange(item)
        });
      }
    });
    return data.map(x => {
      return (
        <div key={x.uuid} className="row align-items-center mb-2 input-form">
          <div className="col-5 light-header-question">
            <span>{x.itemname}</span>
          </div>
          <div className="col-2">{x.value}</div>
          <div className="col-2">{x.unit}</div>
          <div className="col-3">
            <div className="col-3 table-row-align">
              <span className="text-nowrap">{x.reference}</span>
            </div>
          </div>
        </div>
      );
    });
  }

  getUnitName(item) {
    var unitName = "";
    item.unitOptions.map(unit => {
      if (unit.value === item.unitName) {
        unitName = unit.label;
      }
    });
    if (unitName) {
      return " (" + unitName + ")";
    }
  }
  showSlider(item) {
    if (item.slider) {
      const rangeFrom = item.ref_range_minimum;
      const rangeTo = item.ref_range_maximum;
      var labels = {};
      const interval = (rangeTo - rangeFrom) / 10;
      for (let i = rangeFrom; i <= rangeTo; i += interval) {
        labels[Math.ceil(i)] = Math.ceil(i);
      }
      return (
        <div className="mt-4 mb-5">
          <div className="row">
            <div className="col-12 light-header-question">
              <Label>
                {getTrans(item, "item_name")} {this.getUnitName(item)}{" "}
                <span className="float-right">{item.value}</span>{" "}
              </Label>
            </div>
          </div>
          <Slider
            className={`quest-slider ${item.value  === null ? 'slider-not-selected' : ''}`}
            ref={this.rangeRef}
            min={rangeFrom}
            max={rangeTo}
            step={1}
            value={item.value || rangeFrom}
            labels={labels}
            onChange={value => this.sliderChange(value, item)}
          />
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  render() {
    let data = [];
    if(!this.state.initSet){
      this.setState({data: this.getAnswers(this.state.data), initSet: true})
    }
    if (this.state.data.length) {
      data = this.loadData(this.state.data);
    }
    return (
      <div className={`report-table-div ${(data.length === 0) ? 'overflow-hidden' : ''} ${this.state.assDetails && (this.state.assDetails.documented || this.state.assDetails.status === 'a' ? 'non-pointer' : '')}`}>
        {this.state.showTable && <div>
          <div className="row align-items-center">
            <div className="col-5 light-header-question">
              <span>
                <I18 tkey="Item Name" />
              </span>
            </div>
            <div className="col-2 light-header-question">
              <span>
                <I18 tkey="Value" />
              </span>
            </div>
            <div className="col-2 light-header-question">
              <span>
                <I18 tkey="Unit" />
              </span>
            </div>
            <div className="col-3 light-header-question">
              <span>
                <I18 tkey="Reference Range" />
              </span>
            </div>
          </div>
        </div>}
        {this.state.showTable && <hr />}
        {this.state.showTable && this.showRows(data)}
        {_.sortBy(data, "order").map(d => this.showSlider(d))}
        <div />
      </div>
    );
  }
}

export default connect(store => ({
  assessments: store.assessments,
  translations: store.translations
}))(DiagnosticType);
