import React, { Component } from 'react';
import { connect } from 'react-redux';
import I18, { i18Get } from '../../i18';
import './error_page.css';
import moment from 'moment';

class Page404 extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {}
    render() {
        return (
            <div className="h-100 w-100">
                <div className="w-100 error_top">
                    <div className="main_error_div h-100 di-container">
                        <div className="left h-100 d-md-block d-none">
                            <div className="error_table h-100 ">
                                <div className="error_table_cell">
                                    <div className="error_img text-sm-right">
                                        <img src="/images/image_404.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="left h-100 d-block">
                            <div className="error_table h-100">
                                <div className="error_table_cell">
                                    <div className="d-block four_not_four">404</div>
                                    <div className="d-block line" />
                                    <div className="d-block oops_div"><I18 tkey="OOPS SORRY ! LOOKS LIKE YOU ARE LOST" /></div>
                                    <div className="d-block not_available"><I18 tkey="THE PAGE YOU ARE LOOKING IS NOT AVAILABLE" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="error_bottom">
                    <div className="error_table w-100 h-100">
                        <div className="error_table_cell">
                            <div className="error_login_copy_right">
                                <div>&copy; {moment().format('YYYY')} CANKADO <I18 tkey="ALL RIGHTS RESERVED" />.</div>
                                <div><I18 tkey="TERMS OF SERVICE" /> | <I18 tkey="PRIVACY POLICY" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(store => ({
    translations: store.translations,
}))(Page404);
