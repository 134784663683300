import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Radio } from 'antd';
import _ from 'lodash';
import './radio-button-ant.css';
import I18, { i18Get } from '../../../i18';

const RadioGroup = Radio.Group;

class RadioButtonAnt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props,
            value: this.getValue(props),
            color: this.props.color,
            disabled: this.props.disabled,
        };
        this.onChange = this.onChange.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            ...nextProps,
            value: this.getValue(nextProps),
        });
    }
    onChange(e) {
        const checkedValue = e.target.value;
        this.setState({
            value: checkedValue,
        }, () => {
            this.props.onChange(checkedValue);
        });
    }
    getValue(props) {
        let value = null;
        if (!_.isUndefined(props.value)) {
            value = props.value;
        } else if (_.isArray(props.items) && props.items.length) {
            value = props.items[0].value || props.items[0].uuid || null;
        }
        return value;
    }
    onRadioClick(v){
        if(this.props.onClick){
            this.props.onClick(v)
        }
    }
    renderOptions() {
        const options = this.props.items.map((x) => {
            const block = this.props.horizontal ? '' : 'radio-block';
            const color = x.color ? `${x.color}-inverted` : '';
            return <Radio key={x.value || x.uuid} onClick={()=>this.onRadioClick(x.value)} disabled={this.state.disabled} className={`${this.state.value === x.value ? 'selected-option' : ''} ${block} ${color}`} value={x.value || x.uuid}>{x.title}</Radio>;
        });

        return options;
    }
    render() {
        let defval = this.props.defval || 0;
        const inverted = this.props.inverted ? 'inverted' : '';
        const className = `kadoRadio col p-0 ${this.state.color} ${inverted} ${this.props.horizontal && "d-flex"}`;

        return (
            <RadioGroup className={className} value={this.state.value} onChange={this.onChange}>
                {this.renderOptions()}
            </RadioGroup>
        );
    }
}
export default connect(state => ({
    translations: state.translations,
}))(RadioButtonAnt);
