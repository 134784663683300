import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import User from './user';
import Assessments from './assessments';
import Translations from './translations';
import UI from './ui';

export default combineReducers({
    router: routerReducer,
    user: User,
    assessments: Assessments,
    translations: Translations,
    ui: UI,
});
