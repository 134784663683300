import React from 'react';

export default class Assessment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { size, color } = this.props;
        return (
            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="13.5" cy="13.5" r="13.5" fill="#FD902C" />
            <path d="M13.5 2.75C7.57232 2.75 2.75 7.57232 2.75 13.5C2.75 19.4277 7.57232 24.25 13.5 24.25C19.4281 24.25 24.25 19.4277 24.25 13.5C24.25 7.57233 19.4281 2.75 13.5 2.75ZM13.5 22.837C8.35155 22.837 4.16304 18.6485 4.16304 13.5C4.16304 8.35155 8.35155 4.16304 13.5 4.16304C18.648 4.16304 22.837 8.35156 22.837 13.5C22.837 18.6484 18.648 22.837 13.5 22.837Z" fill="white" stroke="white" strokeWidth="0.5" />
            <path d="M11.4674 11.6739C11.4674 11.2213 11.28 10.8558 10.9723 10.6088C10.6705 10.3666 10.2689 10.25 9.84784 10.25C9.42675 10.25 9.02519 10.3666 8.72339 10.6088C8.41572 10.8558 8.22827 11.2213 8.22827 11.6739C8.22827 12.5671 8.95468 13.2935 9.84784 13.2935C10.741 13.2935 11.4674 12.5671 11.4674 11.6739ZM9.84784 11.8804C9.75727 11.8804 9.67971 11.8212 9.65209 11.7395C9.68815 11.7378 9.73224 11.7389 9.78663 11.7415C9.80051 11.7422 9.81544 11.743 9.83105 11.7438C9.88412 11.7467 9.94516 11.75 10 11.75C10.0138 11.75 10.0271 11.7498 10.0399 11.7495C10.0096 11.8259 9.93469 11.8804 9.84784 11.8804Z" fill="white" stroke="white" strokeWidth="0.5" />
            <path d="M17.1523 10.25C16.7312 10.25 16.3296 10.3666 16.0278 10.6088C15.7202 10.8558 15.5327 11.2213 15.5327 11.6739C15.5327 12.5671 16.2591 13.2935 17.1523 13.2935C18.0454 13.2935 18.7718 12.5671 18.7718 11.6739C18.7718 11.2213 18.5844 10.8558 18.2767 10.6088C17.9749 10.3666 17.5734 10.25 17.1523 10.25Z" fill="white" stroke="white" strokeWidth="0.5" />
            <path d="M8.86486 17.5942L8.86504 17.594C10.0383 16.2832 11.7278 15.5326 13.5001 15.5326C15.2729 15.5326 16.962 16.2837 18.1352 17.594L18.1354 17.5942C18.395 17.8849 18.3707 18.3312 18.0803 18.5914C17.9454 18.7127 17.7755 18.7717 17.6088 18.7717C17.4162 18.7717 17.2221 18.6929 17.0824 18.5364C16.1781 17.5259 14.8725 16.9457 13.5001 16.9457C12.1277 16.9457 10.8221 17.5259 9.91783 18.5364L9.91778 18.5365C9.65788 18.8268 9.21133 18.8523 8.92016 18.5916L8.86486 17.5942ZM8.86486 17.5942C8.60514 17.885 8.62957 18.3314 8.92013 18.5916L8.86486 17.5942Z" fill="white" stroke="white" strokeWidth="0.5" />
        </svg>
        );
    }
}