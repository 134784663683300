import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios'
import renderHTML from 'react-render-html';

import AssActions from '../../actions/assessments';
import I18, { i18Get } from '../../i18';
import { Button, Label, PanelPlaceholder, Spinner} from '../../kado/components';

import { Options, MultipleOptions, Numeric, Text, SliderSmiley, Date, Combo, File, SliderScale, DiagnosticType } from './ass-components';
import { getTrans, apiHeaders } from '../../kado/utils';
import Config from '../../cankado-config'
//import style from './print-style';
import './assessments.css';
import LikertScaleTable from './likert-scale-table';
//import './print.css';

class QuestionnaireOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            waiting:false,
            focusId:'',
        };
        this.finishAssessment = this.finishAssessment.bind(this);
        this.nextQuestion = this.nextQuestion.bind(this);
        this.previousQuestion = this.previousQuestion.bind(this);
    }
    setOverview = () => {
        this.props.dispatch(AssActions.ass.setOverview(true));
    }
    ressetOverview = () => {
        this.props.dispatch(AssActions.ass.setOverview(false));
    }
    printPage() {
        this.setState({
            waiting: true,
        });
        axios.post(`${Config.apiRoot}/assessment_pdf/pdf/${this.props.assessments.patient}/`,
                    {
                        assessment: _.find(this.props.assessments.assList, 'selected').uuid,
                        language: this.props.translations.language,
                    },
                    { ...apiHeaders() }
                    ).then(
                        (response) => {
                            window.open(response.data.url, '_blank')
                            this.setState({
                                waiting: false,
                            })
                        }
                    )
    }
    finishAssessment() {
        this.props.dispatch(AssActions.ass.finish(_.find(this.props.assessments.assList, 'selected').uuid, this.props.assessments.selectedAssQA.map(x => x.answer)));
    }
    previousQuestion() {
        const selectedAss = _.find(this.props.assessments.assList, 'selected');
        const nextI = _.findIndex(selectedAss.questionnaires, 'selected') - 1;
        if (nextI > -1) {
            this.props.dispatch(AssActions.ass.selectQuestionnaire(selectedAss.questionnaires[nextI].uuid));
        }
        this.nextQuestionnaireSelect(selectedAss.questionnaires[nextI].uuid)
    }
    nextQuestion() {
        const selectedAss = _.find(this.props.assessments.assList, 'selected');
        const nextI = _.findIndex(selectedAss.questionnaires, 'selected') + 1;
        if (selectedAss.questionnaires.length > nextI) {
            this.props.dispatch(AssActions.ass.selectQuestionnaire(selectedAss.questionnaires[nextI].uuid));
        }
        this.nextQuestionnaireSelect(selectedAss.questionnaires[nextI].uuid)
    }

    getSubQuestions(question) {
        var subQstnArray = ''
        if(question.ansType === 'o' || question.ansType === 'm' ) {
            return question.options.map((option) => {
                if(option.section) {
                    const answer = _.find(this.props.assessments.selectedAssQA, { uuid: question.uuid });
                    if(answer.answer.option === option.uuid || (typeof answer.answer.mOptions !== 'undefined' && answer.answer.mOptions !== null && answer.answer.mOptions.indexOf(option.uuid) !== -1) ) {
                        subQstnArray  = this.renderSection(option.section, true)
                        return subQstnArray
                    }
                }
                
            })
        }
        return subQstnArray
    }

    comboFunction = (uuid)=>{
        this.setState({
            focusId: uuid
        })
    }
    renderQuestion(q, index, length) {
        const language = this.props.translations.language;
        var qstnArray = []
        let sliderClass = (q.ansType === 'f' && q.slider_type_question !== 's') || q.option_style === 'rs' || q.ansType === 'smiley'
        let isEQ_5D_5L= q.ansType === 'f' && q.slider_type_question === 's' 
        return (
            <React.Fragment>
                <div className={`quest-section d-flex ${this.state.focusId === q.uuid ? 'z-top' : ''}`} key={q.uuid} style={{ position:'relative'}}>
                {!isEQ_5D_5L && q.questionnaire && q.questionnaire.show_question_order && <span className="heading">{this.state.assessment_type === 'p' ? index + 1 : q.order}.&nbsp;</span>}
                {!q.questionnaire && <span className="heading">{this.state.assessment_type === 'p' ? index + 1 : q.order}.&nbsp;</span>}                                   
                    <div className="ass-ovrview-qst col p-0 animated fadeIn" 
                        // style={{ minWidth: sliderClass && 520 }}
                        >
                    {!(isEQ_5D_5L) && <span className="heading">
                        {renderHTML(getTrans(q, 'title'))}
                    </span>}
                        <div className={`optionwrap qa-option-wrap d-flex mt-3 ${sliderClass ? `slider-option-wrap mb-3`: ``}`}>
                        {q.ansType === 'o' && q.option_style !== 'rs' && <Options question={q.uuid} options={getTrans(q, 'options')} />}
                        {q.ansType === 'm' && <MultipleOptions question={q.uuid} options={getTrans(q, 'options')} />}
                        {((q.ansType === 'f' && q.slider_type_question !== 's') || (q.ansType === 'o' && q.option_style === 'rs')) && <Numeric question={q.uuid} options={getTrans(q, 'options')} />}
                        {q.ansType === 't' && <Text question={q.uuid} options={getTrans(q, 'options')} />}

                        {q.ansType === 'smiley' && <SliderSmiley question={q.uuid} options={getTrans(q, 'options')} />}
                        {q.ansType === 'date' && <Date question={q.uuid} options={getTrans(q, 'options')} />}
                        {q.ansType === 'combo' && <Combo comboFocus={this.comboFunction} question={q.uuid} options={getTrans(q, 'options')} />}
                        {q.ansType === 'file' && <File question={q.uuid} options={getTrans(q, 'options')} />}
                        {q.ansType === 'f' && q.slider_type_question === 's' && <SliderScale question={q.uuid} options={getTrans(q, 'options')}/>}
                        {q.ansType === 'diagnostic' && <DiagnosticType question={q.uuid} key={q.uuid} options={getTrans(q, 'options')}/>}
                    </div>                
                </div>
            </div>
            {this.getSubQuestions(q)}
            </React.Fragment>
        );
    }
    renderSection(s, showSectitle) {
        const language = this.props.translations.language;
        var questions = s.questions;
        var isLikertScale = s.likertscaletype
        const selectedAss = _.find(this.props.assessments.assList, 'selected');

        return (
            <div key={s.uuid} className="ass-ovrview-qst-sec noscroll w-100">
                {showSectitle &&
                <div className="ass-ovrview-qna">
                    <span className="heading">
                        {renderHTML(getTrans(s, 'title'))}
                    </span>
                </div>
                }
                {isLikertScale !==null ? 
                   <LikertScaleTable 
                     questions = {s}
                     assessment ={this.props.assessments.assList}
                     documented = {selectedAss.documented}
                     section = {s.questions}
                  />
                : 
                questions.map((q, i) => this.renderQuestion(q, i, questions.length))}
            </div>
        );
    }
    renderQuestionnaire(q) {
        return q.sections.map(s => this.renderSection(s, true));
    }
    nextQuestionnaireSelect(questnareId){
        const element = document.getElementById("question-desc");
        element.scrollIntoView(true)
        document.getElementsByClassName(questnareId + "-ques-title")[0].scrollIntoView();
    }
    renderQuestionnaireList(ass) {
        const language = this.props.translations.language;
        const qList = ass.questionnaires;
        if (!_.find(qList, 'selected') && qList.length) {
            this.props.dispatch(AssActions.ass.selectQuestionnaire(qList[0].uuid));
        }
        return [
            qList.map(qstnr => (
                <div key={qstnr.uuid} className={`d-inline-flex qstnr-wrapper ass-tab ${qstnr.uuid}-ques-title ${qstnr.selected ? 'selected' : ''}`} selected={qstnr.selected} uuid={qstnr.uuid} onClick={() => {this.props.dispatch(AssActions.ass.selectQuestionnaire(qstnr.uuid))   
                    this.nextQuestionnaireSelect(qstnr.uuid)}}>
                        {getTrans(qstnr, 'title')}
                </div>
            )),
        ];
    }

    render() {
        const language = this.props.translations.language;
        const { selectedAssQA } = this.props.assessments;
        const selectedAss = _.find(this.props.assessments.assList, 'selected');
        let qstnr = null;
        if (selectedAss && selectedAss.questionnaires) {
            qstnr = _.find(selectedAss.questionnaires, 'selected');
        }
        if (!qstnr) return <PanelPlaceholder>{i18Get('Fetching')}</PanelPlaceholder>;
        const zoom = this.props.assessments.ui.zoom ? 'ass-zoom' : '';
        var questionsListDivClasses = 'qstr-scroll';
        return (
            <div className="h-100" style={{position:'relative'}}>
                <div className="page-content assesment-content hebrew-ass pt-3">
                    <div className="header pt-0">
                        <span className="heading" style={{maxWidth: '100%',display:'inline-flex'}}>
                            {this.renderQuestionnaireList(selectedAss)}
                        </span>
                    </div>

                    { qstnr.background_color &&
                    <style>
                        {
                            `
                                .set-bg-color-div,
                                .set-bg-color-div .ass-ovrview-qna .heading,
                                .set-bg-color-div .quest-section,
                                .set-bg-color-div .quest-section .heading,
                                .set-bg-color-div .page-footer {
                                    background: ${qstnr.background_color}
                                }
                          
                            `
                        }
                    </style>
                    }

                    <div className="set-bg-color-div ass-norm content">
                        <div className={questionsListDivClasses} id="question-desc">
                            {qstnr.instruction_text && 
                            <div className="qstnre-top-help-text" >
                                {getTrans(qstnr, 'instruction_text') && renderHTML(renderHTML(getTrans(qstnr, 'instruction_text')))}
                            </div>
                            }

                            {getTrans(qstnr,"description") &&
                            <div className="mb-2" >{renderHTML(getTrans(qstnr,"description"))}</div>
                            }
                            {qstnr.image &&
                            <div>
                                <img alt="" src={qstnr.image} />
                            </div>
                            }

                            {this.renderQuestionnaire(qstnr)}
                            
                             {/* <LikertScaleTable questions = {qstnr}/> */}

                            {qstnr.copyright_text && <div className="qstnre-bottom-help-text my-3" >
                            {getTrans(qstnr, 'copyright_text') && renderHTML(renderHTML(getTrans(qstnr, 'copyright_text')))}
                            </div>}
                        </div>

                        {(!selectedAss.documented && selectedAss.status !== 'a' ) && (
                            <div className="d-flex align-items-center page-footer" style={{ bottom: 0 }}>
                                {qstnr.uuid !== this.props.assessments.firstQstnr &&
                                <Button className="mr-2" onClick={this.previousQuestion}>
                                    <span className="d-none d-sm-block">{i18Get('Previous')}</span>
                                    <span title={i18Get('Previous')} className="d-block d-sm-none">{i18Get('<')}</span>
                                </Button>
                                }
                                {qstnr.uuid !== this.props.assessments.lastQstnr  ? 
                                    <Button className={''} onClick={this.nextQuestion}>
                                    <span className="d-none d-sm-block">{i18Get('Next')}</span>
                                    <span title={i18Get('Next')} className="d-block d-sm-none">{i18Get('>')}</span>
                                </Button>
                                :
                                 <Button className="button-primary" disabled={this.props.assessments.isFinishAssessmentCompleted === 'PENDING'} onClick={this.finishAssessment}>
                                    {this.props.assessments.isFinishAssessmentCompleted === 'PENDING' && <Spinner />} {i18Get('Finish')}
                                </Button>}
                            </div>
                        )}

                    </div>
                </div>
            </div>
        );
    }
}

export default connect(store => ({
    assessments: store.assessments,
    translations: store.translations,
}))(QuestionnaireOverview);
