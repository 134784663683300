import axios from 'axios';
import events from '../event-names';
import Config from '../../cankado-config';

export default function TranslationFetch(language) {
    return (dispatch) => {
        dispatch({
            type: `${events.TRANSLATION_FETCH}_PENDING`,
            payload: `${language} Fetching...`,
        });
        const url = [`${Config.siteRoot}/locales/Cankado_Terminal_App/${language}/translation.json?`, Math.random()].join();
        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: `${events.TRANSLATION_FETCH}_FULFILLED`,
                    payload: { data: response.data, language },
                });
            })
            .catch((error) => {
                dispatch({
                    type: `${events.TRANSLATION_FETCH}_REJECTED`,
                    payload: error,
                });
            });
    };
}

export function ResetLanguage(language) {
    return (dispatch) => {
        dispatch({
            type: events.RESET_TRANSLATION_LANGUAGE,
            language,
        });
    };
}
