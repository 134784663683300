import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Store from '../store';

const langnow = '';
let language = 'de';

function evalTemplate(strans, params) {
    let tString = strans;
    if (_.isObject(params) && _.isObject(params.S)) {
        Object.keys(params.S).forEach((k) => {
            tString = tString.replace(`\${S.${k}}`, params.S[k]);
        });
    }
    return tString;
}

export function i18Get(key, S) {
    const state = Store.getState();
    const trans = state.translations.data[state.translations.language];
    const dev = state.translations.data.dev;
    try {
        let transText = key;
        if (!_.isEmpty(trans) && key in trans) {
            transText = trans[key];
        } else if (key in dev) {
            transText = dev[key];
        }
        return evalTemplate(transText, { S });
    } catch (err) {
    }
    return key;
}

class I18 extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const key = this.props.tkey;
        language = this.props.translations.language;
        const S = this.props.params;
        return i18Get(key, S);
    }
}

export default connect(state => ({
    translations: state.translations,
}))(I18);
