import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Slider from 'react-rangeslider';
import renderHTML from 'react-render-html';
import axios from 'axios';
import { apiHeaders } from '../../../kado/utils/';
import AssActions from '../../../actions/assessments';
import { i18Get } from '../../../i18';
import { getTrans } from '../../../kado/utils';
import Config from "../../../cankado-config";
import _ from "lodash";
import './slider-scale.css';
import 'react-rangeslider/lib/index.css'

// Assessment Slider
class SliderScale extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            slider_eq_5d_answer: "",
            labels: {},
            reverse:true,
            question:{},
            selectedAss:{}
        };
    }
    onChangeComplete = () => {
        const payload = {
            assessment: this.state.assessment,
            questionnaire: this.state.questionnaire,
            question: this.props.question,
            floatAns: this.state.value,
        }
        axios.patch(`${Config.apiRoot}/patient/assessments/${payload.assessment}/answers/`, [payload], { ...apiHeaders() }).then((response) => {
            if(response.status=== 200){
                this.props.dispatch(AssActions.ass.markAnswer({
                    question: this.props.question,
                    answer: response.data["0"].floatAns,
                }));
            }
        })
    }
    
    handleOnChange = (value) => {
        var prevState = this.state;
        prevState.value = value;
        this.setState(prevState);
    }
    validateValue = (v) => {
        if(this.state.min >= 0 && v && v.includes("-")){
            return false
        }
        let value = Number(v);
        if(value === null || value === 'undefined' ){
            value = ""
        }
        if( value <= this.state.max && value >=this.state.min && value !== 'e'){
            if(this.state.min >= 0 ){
                if(value === '-'){
                    return false
                }
            }
            return true
        }
        return false
    }
    onChange = (e) => {
        let value = e.target.value;
        if(this.validateValue(value)){
            this.setState({
                slider_eq_5d_answer: value
            })
            const payload = {
                assessment: this.state.assessment,
                questionnaire: this.state.questionnaire,
                question: this.props.question,
                slider_eq_5d_answer: value,
            }
            axios.patch(`${Config.apiRoot}/patient/assessments/${payload.assessment}/answers/`, [payload], { ...apiHeaders() }).then((response) => {
                if(response.status=== 200){
                this.props.dispatch(AssActions.ass.markAnswer({
                    question: this.props.question,
                    answer: response.data["0"].slider_eq_5d_answer,
                    type: "slider_eq_5d_answer"
                }));
            }
            })
        }
    }

    componentDidMount() {
        var assessment = _.find(this.props.assessments.assList, 'selected').uuid
        var selectedAss = _.find(this.props.assessments.assList, 'selected')
        var question = _.find(this.props.assessments.selectedAssQA, { uuid: this.props.question });
        this.setState({
            question: question,
            assessment: assessment,
            questionnaire: question.questionnaire.uuid,
            value: question.answer ? question.answer.floatAns : 0,
            slider_eq_5d_answer: question.answer ? question.answer.slider_eq_5d_answer : "",
            selectedAss: selectedAss
        });
        setTimeout(()=>{
            if(document.querySelector('.scale-slider .rangeslider')){
                let labels = Array.from(document.querySelector('.scale-slider .rangeslider').getElementsByClassName('rangeslider__label-item'));
                labels.forEach((l) => {
                if(l.dataset.value % 5 ===0){
                if(l.dataset.value % 10 ===0){
                l.classList.add("wide");
                }
                l.classList.add("Showlabel");
                }
                }); 
            }
            },1000);
        }
    render() {
        this.state.min = this.state.question.config? this.state.question.config.rangeFrom:"";
        this.state.max = this.state.question.config? this.state.question.config.rangeTo:"";
        this.state.step = this.state.question.config? this.state.question.config.stepInterval:1;
        var interval = 1;
        for(var i = this.state.min; i <= this.state.max; i += interval) {
            this.state.labels[Math.ceil(i)] = Math.ceil(i);
        }
        return (
            <div className="scale-slider">
                <div className="sliderdata">
                    <React.Fragment>
                        {this.state.question.title && renderHTML(getTrans(this.state.question,"title"))}
                    </React.Fragment>
                    <div className="slider-userinput">
                        <span>
                            {i18Get("IHRE GESUNDHEIT HEUTE")} =
                        </span>
                        <input style={{height: '25px'}} type="number" disabled={this.state.selectedAss.documented? true: false} value={this.state.slider_eq_5d_answer}  onChange={this.onChange}/>
                    </div>
                </div>
                <div className="slider-wrap">
                    <span className="slider-header">{i18Get("Beste Gesundheit, die Sie sich vorstellen können")}</span>      
                    <Slider className={ this.state.selectedAss.documented ? 'non-pointer' : ''}
                        min={this.state.min}
                        max={this.state.max}
                        step={this.state.step}
                        value={this.state.value}
                        orientation="vertical"
                        labels={this.state.labels}
                        onChange={this.handleOnChange}
                        onChangeComplete={this.onChangeComplete}
                        
                    />
                    <span className="slider-header">{i18Get("Schlechteste Gesundheit, die Sie sich vorstellen können")}</span>      
                </div>
            </div>
        );
    }
}
SliderScale.propTypes = {
    dispatch: PropTypes.func.isRequired,
    translations:PropTypes.object.isRequired,
};
export default connect(store => ({
    assessments: store.assessments,
    translations : store.translations,
}))(SliderScale);