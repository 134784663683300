import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getTrans } from '../../../kado/utils';
import { CheckBoxGroup } from '../../../kado/components';
import AssActions from '../../../actions/assessments';

import './ass-components.css';

class Options extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.ansChange = this.ansChange.bind(this);
    }
    ansChange(v) {
        this.props.dispatch(AssActions.ass.markAnswer({
            question: this.question.uuid,
            answer: v,
        }));
        var  ans = _.find(this.props.assessments.selectedAssQA, { uuid: this.question.uuid });
        this.props.dispatch(AssActions.ass.documentQuestion(_.find(this.props.assessments.assList, 'selected').uuid, [ans.answer]));
    }
    render() {
        const selectedAss = _.find(this.props.assessments.assList, 'selected');
        this.question = _.find(this.props.assessments.selectedAssQA, { uuid: this.props.question });
        const items = this.question.options.map(x => ({
            value: x.uuid,
            label: getTrans(x, 'title'),
        }));
        return (
            <div>
                <CheckBoxGroup
                    disabled={selectedAss.documented}
                    options={items}
                    onChange={this.ansChange}
                    value={this.question.answer.mOptions}
                    color=""
                    block={this.question.option_style === 'l'}
                />
            </div>
        );
    }
}
export default connect(store => ({
    assessments: store.assessments,
    translations: store.translations,
}))(Options);
