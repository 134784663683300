import { Modal } from 'antd';

import { i18Get } from '../../i18';

const confirm = Modal.confirm;

function ConfirmBox(content, onSuccess, cancelCall) {
    confirm({
        title: i18Get('Cankado confirmation'),
        content,
        okText: i18Get('Yes'),
        cancelText: i18Get('No'),
        onOk() {
            if (onSuccess) {
                onSuccess();
            }
        },
        onCancel() {
            if (cancelCall) {
                cancelCall();
            }
        },
    });
}

export { ConfirmBox };
