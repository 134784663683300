import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import AssActions from '../../../actions/assessments';
import './ass-components.css';
import { i18Get } from '../../../i18/index';
import { DatePicker } from 'antd';
import moment from 'moment';

class Options extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.updateDate = this.updateDate.bind(this);
    }
    updateDate(date) {
        this.props.dispatch(AssActions.ass.markAnswer({
            question: this.question.uuid,
            answer: date ? moment(date).format('YYYY-MM-DD') : "",
        }));

        var ans = _.find(this.props.assessments.selectedAssQA, { uuid: this.question.uuid });
        this.props.dispatch(AssActions.ass.documentQuestion(_.find(this.props.assessments.assList, 'selected').uuid, [ans.answer]));
    }
    render() {
        const selectedAss = _.find(this.props.assessments.assList, 'selected');
        this.question = _.find(this.props.assessments.selectedAssQA, { uuid: this.props.question });
        return (
            <div className={`${selectedAss.documented || selectedAss.status === 'a' ? 'non-pointer' : ''}`}>
                <DatePicker
                    onChange={e => this.updateDate(e)}
                    placeholder={i18Get("Select date")}
                    className="b-margin w-100 py-0"
                    disabledDate={v => moment().isBefore(v)}
                    disabledTime={v => moment().isBefore(v)}
                    defaultValue={(this.question.answer && this.question.answer.textAns && typeof this.question.answer.textAns !== 'undefined' ) ? moment(this.question.answer.textAns, 'YYYY-MM-DD'): null} 
                    allowEmpty={false}
                    allowClear={true}
                    format="DD.MM.YYYY"
                />
            </div>
        );
    }
}
export default connect(store => ({
    assessments: store.assessments,
    translations: store.translations,
}))(Options);
