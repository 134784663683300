import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import locations from '../../../router/locations';


import AssessmentKey from '../../assessments/ass-answer-mc';
import Login from '../../login';


import './main-container.css';

class MainContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="mc-content-wrapper autoscroll">
                <Switch>
                    <Route exact path={locations.dashboard} component={Login} />
                    <Route exact path={locations.assessmentKey} component={AssessmentKey} />
                    <Route exact path={locations.assessment} component={AssessmentKey} />
                    <Redirect to={locations.not_found} />
                </Switch>
            </div>
        );
    }
}

export default withRouter(connect(state => ({}))(MainContainer));
