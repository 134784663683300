// export default {
//     apiRoot: 'https://python3-api.kraftvoll.co',
//     siteRoot: 'https://python3-mamebe.kraftvoll.co',
//     ci1: 'EuU7veCMBycMH9ELUl8',
//     ci2: 'YLJww6LlZxYV4BSzD0lFP',
//     publicUrl: '',
//     termsUrl: 'https://cankado.com/terms',
//     policyUrl: 'https://cankado.com/policy',
// };


// export default {
//     apiRoot: 'https://oq-api.cankado.com',
//     siteRoot: 'https://oq-mamebe.cankado.com',
//     ci1: 'EuU7veCMBycMH9ELUl8',
//     ci2: 'YLJww6LlZxYV4BSzD0lFP',
//     publicUrl: '',
//     termsUrl: 'https://oq.cankado.com/terms',
//     policyUrl: 'https://oq.cankado.com/policy',
// };



export default {
    apiRoot: 'https://api.cankado.com',
    siteRoot: 'https://mamebe.cankado.com',
    ci1: 'EuU7veCMBycMH9ELUl8',
    ci2: 'YLJww6LlZxYV4BSzD0lFP',
    publicUrl: '',
    termsUrl: 'https://cankado.com/terms',
    policyUrl: 'https://cankado.com/policy',
};
