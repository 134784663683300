import _ from 'lodash';
import eventNames from '../../actions/event-names';
import Config from '../../cankado-config';

const initialState = {
    isAuthenticated: false,
    isCheckLoginComplete: false,
    isProfilePicSaveCompleted: false,
    total_kados: 0,
    isHijackCompleted: false,
    nokia_user: null,
};

function getHeader(action) {
    return {
        authorization: [action.payload.data.token_type, action.payload.data.access_token].join(' '),
    };
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
    // CHECK LOGIN
    case `${eventNames.CHECK_LOGIN}_PENDING`:
        return {
            ...state,
            isCheckLoginComplete: 'PENDING',
        };
    case `${eventNames.CHECK_LOGIN}_FULFILLED`:
        return {
            ...state,
            isCheckLoginComplete: 'FULFILLED',
            isAuthenticated: action.payload.data.access_token,
            headers: {
                ...getHeader(action),
            },
            ...action.payload.data,
        };
    case `${eventNames.CHECK_LOGIN}_REJECTED`:
        return {
            ...state,
            isCheckLoginComplete: 'REJECTED',
        };
    default:
        return state;
    }
}
