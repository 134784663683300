import React from 'react';
import configFile from '../../cankado-config';
import './footer.css';
import I18 from '../../i18';
import moment from 'moment';

export default class Footer extends React.Component {

       render() {
        return (
                <div className="display_table">
                    <div className="display_table_cell">
                        <div className="login_copy_right">
                            <div>&copy; {moment().format('YYYY')} CANKADO <I18 tkey="ALL RIGHTS RESERVED" />.</div>
                            <div><a href={configFile.termsUrl } target="_blank"><I18 tkey="TERMS OF SERVICE"/></a><a href={configFile.policyUrl} target="_blank"> | <I18 tkey="PRIVACY POLICY" /></a></div>
                        </div>
                    </div>
                </div> 
        )
    }
   
}
;