import events from '../../event-names';

export function selectQstnr(uuid) {
    return {
        type: events.SELECT_QSTNR,
        payload: uuid,
    };
}
export function selectQstn(uuid) {
    return {
        type: events.SELECT_QUESTION,
        payload: uuid,
    };
}
export function markAnswer(payload) {
    return {
        type: events.MARK_ANSWER,
        payload,
    };
}
export function setZoom(payload) {
    return {
        type: events.ASS_UI_ZOOM,
        payload,
    };
}
export function setOverview(payload) {
    return {
        type: events.ASS_UI_OVERVIEW,
        payload,
    };
}
export function FetchAssessmentClearError() {
    return {
        type: events.CLEAR_ERROR,
    };
}
