import events from '../event-names';

export function ToggleSecondarySB() {
    return {
        type: events.TOGGLE_SECONDARY_SIDEBAR,
    };
}
export function ToggleTertiarySB() {
    return {
        type: events.TOGGLE_TERTIARY_SIDEBAR,
    };
}
export function ToggleFavDrugList() {
    return {
        type: events.TOGGLE_FAVDRUGLIST,
    };
}

export function ToggleAssList() {
    return {
        type: events.TOGGLE_ASSLIST,
    };
}

export function ClearNavigateTo() {
    return {
        type: events.CLEAR_NAVIGATE_TO,
    };
}

export function SetNavigateTo(url) {
    return {
        type: events.SET_NAVIGATE_TO,
        payload: url,
    };
}
