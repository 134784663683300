import React from 'react';

export default class F80 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { size, color } = this.props;
        return (
            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="13.5" cy="13.5" r="13.5" fill="#1DE331" />
            <path d="M13.5 2.75C7.57232 2.75 2.75 7.57232 2.75 13.5C2.75 19.4277 7.57232 24.25 13.5 24.25C19.4281 24.25 24.25 19.4277 24.25 13.5C24.25 7.57233 19.4281 2.75 13.5 2.75ZM13.5 22.837C8.35155 22.837 4.16304 18.6485 4.16304 13.5C4.16304 8.35155 8.35155 4.16304 13.5 4.16304C18.648 4.16304 22.837 8.35156 22.837 13.5C22.837 18.6484 18.648 22.837 13.5 22.837Z" fill="white" stroke="white" strokeWidth="0.5" />
            <path d="M11.4674 11.6739C11.4674 10.7808 10.741 10.0544 9.84784 10.0544C8.95468 10.0544 8.22827 10.7808 8.22827 11.6739C8.22827 12.5671 8.95468 13.2935 9.84784 13.2935C10.741 13.2935 11.4674 12.5671 11.4674 11.6739ZM9.64131 11.6739C9.64131 11.5604 9.73436 11.4674 9.84784 11.4674C9.96131 11.4674 10.0544 11.5604 10.0544 11.6739C10.0544 11.7874 9.96131 11.8804 9.84784 11.8804C9.73436 11.8804 9.64131 11.7874 9.64131 11.6739Z" fill="white" stroke="white" strokeWidth="0.5" />
            <path d="M17.1523 10.0544C16.2591 10.0544 15.5327 10.7808 15.5327 11.6739C15.5327 12.5671 16.2591 13.2935 17.1523 13.2935C18.0454 13.2935 18.7718 12.5671 18.7718 11.6739C18.7718 10.7808 18.0454 10.0544 17.1523 10.0544ZM17.1523 11.8804C17.0385 11.8804 16.9458 11.7876 16.9458 11.6739C16.9458 11.5603 17.0385 11.4674 17.1523 11.4674C17.266 11.4674 17.3588 11.5603 17.3588 11.6739C17.3588 11.7876 17.266 11.8804 17.1523 11.8804Z" fill="white" stroke="white" strokeWidth="0.5" />
            <path d="M18.1354 17.7102L18.1352 17.7104C16.962 19.0212 15.2724 19.7718 13.5001 19.7718C11.7273 19.7718 10.0383 19.0207 8.86507 17.7104L8.86487 17.7102C8.6052 17.4195 8.62956 16.9732 8.91994 16.713C9.05484 16.5917 9.22473 16.5326 9.39143 16.5326C9.58409 16.5326 9.77817 16.6115 9.91783 16.7679C10.8221 17.7784 12.1277 18.3587 13.5001 18.3587C14.8725 18.3587 16.1781 17.7784 17.0824 16.7679L17.0825 16.7679C17.3424 16.4776 17.7889 16.452 18.0801 16.7128L18.1354 17.7102ZM18.1354 17.7102C18.3951 17.4194 18.3707 16.973 18.0801 16.7128L18.1354 17.7102Z" fill="white" stroke="white" strokeWidth="0.5" />
        </svg>

        );
    }
}