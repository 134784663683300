export default {
    // AUTH
    CHECK_LOGIN: 'CHECK_LOGIN',
    CHECK_LOGIN_PENDING: 'CHECK_LOGIN_PENDING',
    CHECK_LOGIN_FULFILLED: 'CHECK_LOGIN_FULFILLED',
    CHECK_LOGIN_REJECTED: 'CHECK_LOGIN_REJECTED',
    LOGOUT: 'LOGOUT',

    // ASSESSMENT
    FETCH_ASS_KEY_LIST: 'FETCH_ASS_KEY_LIST',
    FETCH_ASS_KEY: 'FETCH_ASS_KEY',
    FETCH_ASS: 'FETCH_ASS',
    SELECT_QSTNR: 'SELECT_QSTNR',
    SELECT_QUESTION: 'SELECT_QUESTION',
    MARK_ANSWER: 'MARK_ANSWER',
    ASS_UI_OVERVIEW: 'ASS_UI_OVERVIEW',
    ASS_UI_ZOOM: 'ASS_UI_ZOOM',
    CLEAR_ERROR: 'CLEAR_ERROR',

    // CENTER DEPARTMENT
    DEPARTMENT_LIST_FETCH: 'DEPARTMENT_LIST_FETCH',
    DEPARTMENT_LIST_SELECT_UPDATE: 'DEPARTMENT_LIST_SELECT_UPDATE',
    DEPARTMENT_PHY_LIST_SELECT_UPDATE: 'DEPARTMENT_PHY_LIST_SELECT_UPDATE',
    DEPARTMENT_GENERAL_INFO_SELECT: 'DEPARTMENT_GENERAL_INFO_SELECT',

    // DIARY
    FETCH_MY_HEALTH: 'FETCH_MY_HEALTH',
    FETCH_MY_PAIN: 'FETCH_MY_PAIN',
    FETCH_MY_DRUG_INTAKES: 'FETCH_MY_DRUG_INTAKES',
    FETCH_MY_ASSESSMENTS: 'FETCH_MY_ASSESSMENTS',
    FETCH_MY_SYMPTOMS: 'FETCH_MY_SYMPTOMS',
    SET_DOCUMENTATION_DATE: 'SET_DOCUMENTATION_DATE',
    FETCH_ASS_ANSWER: 'FETCH_ASS_ANSWER',
    SAVE_ASS_ANSWERS: 'SAVE_ASS_ANSWERS',
    DOCUMENT_QUESTION: 'DOCUMENT_QUESTION',
    FINISH_ASS: 'FINISH_ASS',
    SAVE_IFL: 'SAVE_IFL',

    // UI
    TOGGLE_DIARY_ENTRYGROUP_VIEW: 'TOGGLE_DIARY_ENTRYGROUP_VIEW',
    TOGGLE_SECONDARY_SIDEBAR: 'TOGGLE_SECONDARY_SIDEBAR',
    TOGGLE_TERTIARY_SIDEBAR: 'TOGGLE_TERTIARY_SIDEBAR',
    TOGGLE_FAVDRUGLIST: 'TOGGLE_FAVDRUGLIST',
    TOGGLE_ASSLIST: 'TOGGLE_ASSLIST',
    CLEAR_NAVIGATE_TO: 'CLEAR_NAVIGATE_TO',
    SET_NAVIGATE_TO: 'SET_NAVIGATE_TO',

    // USER PROFILE
    USER_PROFILE_FETCH: 'USER_PROFILE_FETCH',

    // TRANSLATIONS
    TRANSLATION_FETCH: 'TRANSLATION_FETCH',
    RESET_TRANSLATION_LANGUAGE: 'RESET_TRANSLATION_LANGUAGE',

    // FILES
    FILES_FETCH: 'FILES_FETCH',
    FILES_FILTER: 'FILES_FILTER',
    FILE_SELECT: 'FILE_SELECT',
    FILE_SAVE: 'FILE_SAVE',
    FILE_EDIT_SAVE: 'FILE_EDIT_SAVE',
    FILE_DELETE: 'FILE_DELETE',
    CLEAR_PREVIOUS_PAGE_CLICK: 'CLEAR_PREVIOUS_PAGE_CLICK',
    CLEAR_NEXT_PAGE_CLICK: 'CLEAR_NEXT_PAGE_CLICK',
    NEXT_PAGE_CLICK: 'NEXT_PAGE_CLICK',
    PREVIOUS_PAGE_CLICK: 'PREVIOUS_PAGE_CLICK',

    // PATIENT PROFILE
    PATIENT_PROFILE_FETCH: 'PATIENT_PROFILE_FETCH',
    PATIENT_USERPROFILE_EDIT: 'PATIENT_USERPROFILE_EDIT',
    PATIENT_SETTINGS_EDIT: 'PATIENT_SETTINGS_EDIT',

    // COUNTRY
    COUNTRY_FETCH: 'COUNTRY_FETCH',

};
