import React from 'react';
import _ from 'lodash';
import { Slider, Icon } from 'antd';

class IconSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(value) {
        this.setState({ value });
        if (_.isFunction(this.props.onChange)) this.props.onChange(value);
    }
    render() {
        const { value } = this.state;
        const arrayToObject = (arr, keyField) => Object.assign({}, ...arr.map(item => ({[item[keyField]]: item})))
        const step = (this.props.max - this.props.min) / 10
        const steps = Array.apply(null, {length: step > 1 ? 21 : 11 }).map(function(value, index){
            let i = index * (step > 1 ? step/2 : 1);
            return {
                i,
                label: <strong>{ i % step === 0 ? i : '\'' }</strong>,
            };
        });
        const marks = arrayToObject(steps, 'i')
        return (
            <div className="kado-slider">
                <Slider marks={marks} {...this.props} onChange={this.handleChange} value={value} />
            </div>
        );
    }
}
export default IconSlider;
