export default {
    options: [
        { label: 'عربى', value: 'ar' },
        { label: '简体中文）', value: 'zh' },
        { label: 'Nederlands', value: 'nl' },
        { label: 'English', value: 'en' },
        { label: 'Française', value: 'fr' },
        { label: 'Deutsch', value: 'de' },
        { label: 'Ελληνικά', value: 'gr' },
        { label: 'हिन्दी', value: 'hi' },
        { label: 'Magyar', value: 'hu' },
        { label: 'Italiana', value: 'it' },
        { label: 'മലയാളം', value: 'ml' },
        { label: 'norsk', value: 'nw' },
        { label: 'Portuguesa', value: 'pt' },
        { label: 'русский', value: 'ru' },
        { label: 'Española', value: 'es' },
        { label: 'Türk', value: 'tr' },
        { label: 'עברית', value: 'he' },
        { label: 'Polski', value: 'pl' },
    ],
};