import { Modal } from 'antd';
import { i18Get } from '../../../../i18';

const confirm = Modal.confirm;

function ConfirmBox(heading,content, onSuccess, cancelCall, width,okText, cancelText) {

    confirm({
        width: width ? width : '416px',
        title: heading,
        content,
        okText: okText ? i18Get(okText):i18Get('Yes'),
        cancelText: cancelText ? i18Get(cancelText):i18Get('No'),
        onOk() {
            if (onSuccess) {
                onSuccess();
            }
        },
        onCancel() {
            if (cancelCall) {
                cancelCall();
            }
        },
    });
}

export default ConfirmBox;
