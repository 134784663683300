import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import _ from 'lodash';
import AssActions from '../../../actions/assessments';
import './ass-components.css';
import { i18Get } from '../../../i18/index';
import  Select  from 'react-select';
import Config from '../../../cankado-config';
import { apiHeaders } from '../../../kado/utils';
import renderHTML from 'react-render-html';
const { Option } = Select;

class Options extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            selectedAnswer: null,
            textLength: 0,
            selectedOption: ""
        };
        this.ansBlur = this.ansBlur.bind(this);
    }

    ansBlur(event) {
        this.props.dispatch(AssActions.ass.markAnswer({
            question: this.question.uuid,
            answer: event.target.value,
        }));
        var ans = _.find(this.props.assessments.selectedAssQA, { uuid: this.question.uuid });
        this.props.dispatch(AssActions.ass.documentQuestion(_.find(this.props.assessments.assList, 'selected').uuid, [ans.answer]));
    }


    onMouseEnter = (e) => { 
        if(e.keyCode === 13) {
            this.state.items = []
            var val ={label:e.target.value}
            this.ansChange(val, {label:e.target.value})
        }
    }
    onFocus = (e) => {
        var prevState = this.state
        if(this.question.combo_type === 'new') {
            prevState.items = this.question.options.map(x => ({
                value: x.title,
                label: x.title,
            }));
            this.setState(prevState)
        }
    }
    ansChange=(e, o)=>{
        // console.log("eee",e)
        if(typeof e === 'undefined' || !e) {
            e = {
                label: null
            }
        }
        this.state.selectedOption = e
        this.state.textLength = 0
        this.props.dispatch(AssActions.ass.markAnswer({
            question: this.question.uuid,
            answer: e.label,
        }));

        // Single question save
        var ans = _.find(this.props.assessments.selectedAssQA, { uuid: this.question.uuid });
        this.props.dispatch(AssActions.ass.documentQuestion(_.find(this.props.assessments.assList, 'selected').uuid, [ans.answer]));
    }

    searchOption=(e)=>{
        // console.log("eesreach",e)
        var prevState = this.state
        prevState.textLength = e.length
        if(e.length >= 2){
            if(this.question.combo_type === 'new') {
                prevState.items = this.question.options.map(x => ({
                    value: x.title,
                    label: x.title,
                }));
                this.setState(prevState)
            } else {
                axios.get(`${Config.apiRoot}/questionnaires/question/combotype/search/?search=${e}&combo_type=${this.question.combo_type}`, { ...apiHeaders() }).then((response) => {
                    var items = []
                    response.data.map((x)=>{
                        items.push({
                            value: x.title,
                            label: x.title,
                        })
                    })
                    prevState.items = items
                    this.setState(prevState)
                })
            }
        } else {
            prevState.items = []
            this.setState(prevState)
        }
    }
    inFoucus =()=>{
        this.props.comboFocus(this.props.question,true)
    }

    render() {
        const selectedAss = _.find(this.props.assessments.assList, 'selected');
        this.question = _.find(this.props.assessments.selectedAssQA, { uuid: this.props.question });
        if(!this.state.selectedOption){
            this.state.selectedOption = this.question.answer.textAns ? {value: renderHTML(this.question.answer.textAns),label: renderHTML(this.question.answer.textAns)} : ''
        }
        
        return (
            <div onClick={this.inFoucus} className={`pt-2 b-margin${selectedAss.documented || selectedAss.status === 'a' ? 'non-pointer' : ''}`}>
                <Select
                className="w-100"
                autoComplete
                isSearchable
                closeOnSelect={true}
                placeholder={i18Get('Please type here ...')}
                isClearable={true}
                value={this.state.selectedOption}
                onInputKeyDown={this.onMouseEnter}
                onFocus={this.onFocus}
                onChange={(e, o)=>this.ansChange(e ,o)}
                noResultsText={this.state.textLength < 2 ? i18Get('Your search term must have 2 or more characters') : i18Get('Not found')}
                onInputChange={this.searchOption}
                options={this.state.items}
                maxLength="60"
            />
            </div>
        );
    }
}
export default connect(store => ({
    assessments: store.assessments,
    translations: store.translations,
}))(Options);
