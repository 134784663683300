import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './button.css';

/* *************************************
button used in sidebar secondary
Props:
    title = { title }
    color = {blue, green} default: green
    size = { big, block } default: big
    icon= { iconName }
    onClick = { onClick() }
************************************************ */

export default class Button extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    renderIcon() {
        const { icon } = this.props;
        const className = `fa ${icon} buttonIcon`;
        if (icon) {
            return <i className={className} />;
        }
        return false;
    }
    render() {
        const {
            color, onClick, title, size,
        } = this.props;
        let { disabled } = this.props;
        disabled = disabled ? 'button-disabled' : '';
        return (
            <button {...this.props} className={`button btn-with-brand ${size} ${disabled} ${this.props.className}`} onClick={onClick}>
                {title || this.props.children}
                {this.renderIcon()}
            </button>
        );
    }
}

Button.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

Button.defaultProps = {
    title: '',
    color: 'green',
    size: 'big',
    icon: '',
    disabled: null,
    onClick: () => console.log('Button Clicked'),
};
