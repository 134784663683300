import axios from 'axios';
import CryptoJS from 'crypto-js';
import Store from '../store';
import Config from '../cankado-config';

export default {
    setupInterceptors: (store) => {

        axios.interceptors.request.use((config) => {
            const user = Store.getState().user
            const url = config.url;
            if(user.isCheckLoginComplete === 'FULFILLED' && !url.startsWith(`${Config.siteRoot}/locales/`)) {
                // Extract the URL components
                const parts = url.split('?');
                let queryString = '';

                if (parts.length > 1) {
                    // Join the query string back together
                    queryString = '?' + parts.slice(1).join('?');
                }

                // Extract URL path and define encryption parameters
                const urlPath = parts[0].replace(Config.apiRoot + '/', '');
                const key = Config.ci1.slice(0, 16);
                const iv = user.access_token.slice(0, 16);
             
                // Encrypt the URL path
                const cipher = CryptoJS.AES.encrypt(urlPath, CryptoJS.enc.Utf8.parse(key), {
                    iv: CryptoJS.enc.Utf8.parse(iv),
                    mode: CryptoJS.mode.CBC,
                });
                const encryptedText = cipher.toString();
                // Construct the final encrypted URL
                const encryptedUrl = `${Config.apiRoot}/${encryptedText}${queryString}`;
                config.url = encryptedUrl;
            }
            return config;
        }, (error) => {
            return Promise.reject(error);
        });



        axios.interceptors.response.use(
            response => response,
            (error) => {
                if (error.response.status === 401) {
                    window.location.href = Config.siteRoot;
                } else if (error.response.status === 404 && error.config.url.indexOf('/checklogin') !== -1) {
                    window.location.href = Config.siteRoot;
                } else {
                    return Promise.reject(error);
                }
            },
        );
    },
};
