


import React from 'react';
/* **********************
 Props:
 Size={}
 Color={}
 *********************** */

export default class Minus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        // const { color } = this.props;
        return (
            <svg width="32" height="4" viewBox="0 0 32 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="0.461243" y1="1.68384" x2="31.2305" y2="1.68384" stroke="#8B8C93" strokeWidth="3" />
            </svg>
        );
    }
}