import React from 'react';

/*
To add loading spinner
*/
import './spinner.css';

export default class Spinner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const className = ['fa fa-spinner fa-pulse fa-fw', this.props.className ? this.props.className : ''].join(' ');
        return <i className={className} />;
    }
}
