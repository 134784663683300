import _ from 'lodash';
import events from '../../actions/event-names';
import Tostify from '../../kado/utils/tostify';
import renderHTML from 'react-render-html';
import { i18Get } from '../../i18';

const initialState = {
    assKeyList: [],
    assList: [],
    selectedAssQA: [],
    answers: [],
    patient:'',
    ui: {
        zoom: false,
        overview: true,
    },
    isFinishAssessmentCompleted: false,
    isFetchAssessmentUuid: false,
    assessmentUuid: '',
    isAnswerFetchCompleted: false,
};

function setAnswer(state, action) {
    const q = _.find(state.selectedAssQA, { uuid: action.payload.question });

    if (q.ansType === 'o') {
        q.answer.option = action.payload.answer;
    } else if (q.ansType === 'm') {
        q.answer.mOptions = action.payload.answer || [];
    } else if (q.ansType === 'f' && q.slider_type_question !== 's') {
        q.answer.floatAns = action.payload.answer;
    } else if (q.ansType === 't') {
        q.answer.textAns = action.payload.answer;
    } else if (q.ansType === 't') {
        q.answer.textAns = action.payload.answer;
    } else if (q.ansType === 'smiley') {
        q.answer.floatAns = action.payload.answer;
    } else if (q.ansType === 'date') {
        q.answer.textAns = action.payload.answer;
    } else if (q.ansType === 'combo') {
        q.answer.textAns = action.payload.answer;
    } else if (q.ansType === 'file') {
        q.answer.file_answers = action.payload.answer;
    } else if (q.ansType === 'f' && q.slider_type_question === 's') {
        if(action.payload.type === "slider_eq_5d_answer"){
            q.answer.slider_eq_5d_answer = action.payload.answer;
        }else{
            q.answer.floatAns = action.payload.answer;
        }
    } else if (q.ansType === 'diagnostic') {
        q.answer.diagnostic_answers = action.payload.answer;
    } 
    return state.selectedAssQA;
}

function getAnswerOfQuestion(questionUUID) {
    const q = this.assessmentAns.find(x => x.question === questionUUID);
    return q || null;
}

function formatSubSection(sec, qstnr, quest, qOrder, ass, answers) {
    var subQstnArray = []
    if(quest.ansType === 'o'|| quest.ansType === 'm') {
        quest.options.map((option) => {
                if(option.section) {
                    option.section.questions.map((quest, qOrder) => {
                        subQstnArray = [...subQstnArray, ...formatSubQuestion(option.section, qstnr, quest, qOrder, ass, answers)]

                    })
                }
        })
    }

    return subQstnArray
}

function formatSubQuestion(sec, qstnr, quest, qOrder, ass, answers) {
    var subQstnArray = []
    const q = {
        ...quest,
        title: quest.title,
        section: sec,
        questionnaire: qstnr,
        assessment_type: ass.assessment_type,
        order: ass.assessment_type === 'p' ? qOrder + 1 : quest.order,
    };
    const answer = _.find(answers, { question: quest.uuid });

    q.answer = {
        ...answer,
        option: answer ? answer.option : null,
        mOptions: answer ? answer.mOptions : null,
        floatAns: answer ? answer.floatAns : null,
        textAns: answer ? answer.textAns : null,
        assessment: ass.uuid,
        question: quest.uuid,
        questionnaire: qstnr.uuid,
        level: quest.level,
    };

    subQstnArray = [q, ...formatSubSection(sec, qstnr, quest, qOrder, ass, answers)]

    return subQstnArray
}


function processQuestions(state, ass, ans) {
    const answers = ans || state.answers || [];
    var questions = [];
    if (!ass || !ass.questionnaires) {
        return { ass, questions: [], questionnaires: [] };
    }
    const obj = {
        ...ass,
        questionnaires: ass.questionnaires.map((qstnr, i) => ({
            ...qstnr,
            title: qstnr.title,
            description: qstnr.description,
            sections: qstnr.sections.map(sec => ({
                ...sec,
                title: sec.title,
                questions: sec.questions.map((quest, qOrder) => {

                    const q = {
                        ...quest,
                        title: quest.title,
                        section: sec,
                        questionnaire: qstnr,
                        assessment_type: ass.assessment_type,
                        triggered_assessment: ass.triggered_assessment,
                        order: ass.assessment_type === 'p' ? qOrder + 1 : quest.order,
                    };
                    const answer = _.find(answers, { question: quest.uuid });
                
                    q.answer = {
                        ...answer,
                        option: answer ? answer.option : null,
                        mOptions: answer ? answer.mOptions : null,
                        floatAns: answer ? answer.floatAns : null,
                        textAns: answer ? answer.textAns : null,
                        file_answers: answer ? answer.file_answers : [],
                        assessment: ass.uuid,
                        question: quest.uuid,
                        questionnaire: qstnr.uuid,
                        level: quest.level,
                        diagnostic_answers: answer ? answer.diagnostic_answers : [],
                    };
                    
                    const subQns = formatSubSection(sec, qstnr, quest, qOrder, ass, answers)
                    questions.push(q);
                    questions = [...questions, ...subQns]
                    return q;
                }),
            })),
        })),
    };
    return { ass: obj, questions };
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case [events.FETCH_ASS_KEY_LIST, '_FULFILLED'].join(''):
        return {
            ...state,
            assKeyList: [...action.payload.data],
        };
    case [events.FETCH_ASS_KEY, '_PENDING'].join(''):
        return {
            ...state,
            assList: [],
            selectedAssQA: [],
            answers: [],
            isFetchAssessmentUuid: 'PENDING',
            isFinishAssessmentCompleted: false,
        };
    case [events.FETCH_ASS_KEY, '_FULFILLED'].join(''):
        return {
            ...state,
            assList: [...state.assList, action.payload.data].map(x => ({
                ...x,
                selected: x.uuid === action.payload.data.uuid,
            })),
            assessmentUuid: action.payload.data.uuid,
            isFetchAssessmentUuid: 'FULFILLED',
        };
    case [events.FETCH_ASS_KEY, '_REJECTED'].join(''): {
        if (action.payload.response.status === 400 && action.payload.response && action.payload.response.data.error) {
            Tostify.showError(renderHTML(i18Get(action.payload.response.data.error)));
        } else if (action.payload.response && (action.payload.response.status === 404 && action.payload.response.data.detail) ||
                    (action.payload.response.status === 400 && action.payload.response.data.status)) {
            if(action.payload.response.status === 404) {
                Tostify.showError(renderHTML(i18Get('Invalid Assessment Code'))); 
            } else {
                Tostify.showError(i18Get('Assessment is in sleep mode.Please contact physician'));
            }
        } else {
            Tostify.showError(renderHTML(i18Get('Invalid Assessment Code')));
        }
        return {
            ...state,
            isFetchAssessmentUuid: 'REJECTED',
        };
    }
    case [events.FETCH_ASS, '_FULFILLED'].join(''):
        const qstnares =  _.sortBy(action.payload.data.questionnaires, 'order');
        action.payload.data.questionnaires = qstnares;
        const qstnrlength = qstnares.length
        return {
            ...state,
            assList: _.uniqBy([{ ...processQuestions(state, action.payload.data).ass, selected: true }, ...state.assList.map(x => ({ ...x, selected: false }))], 'uuid'),
            selectedAssQA: processQuestions(state, action.payload.data).questions,
            patient:action.payload.data.patient,
            lastQstnr: qstnares[qstnrlength-1].uuid,
            firstQstnr: qstnares[0].uuid
        };

    case [events.FETCH_ASS_ANSWER, '_PENDING'].join(''):
        return {
            ...state,
            isAnswerFetchCompleted: 'PENDING',
        };
    case [events.FETCH_ASS_ANSWER, '_FULFILLED'].join(''):
        return {
            ...state,
            assList: state.assList.map(x => ({
                ...processQuestions(state, x, action.payload.data).ass,
            })),
            answers: action.payload.data,
            selectedAssQA: processQuestions(state, _.find(state.assList, 'selected'), action.payload.data).questions,
            isAnswerFetchCompleted: 'FULFILLED',
        };

    case events.SELECT_QSTNR:
        return {
            ...state,
            assList: state.assList.map(x => ({
                ...x,
                questionnaires: x.questionnaires.map(q => ({
                    ...q,
                    selected: x.selected && q.uuid === action.payload,
                })),
            })),
            selectedAssQA: state.selectedAssQA.map(x => ({
                ...x,
                selected: x.selected && x.questionnaire.uuid === action.payload,
            })),
        };
    case events.SELECT_QUESTION:
        return {
            ...state,
            selectedAssQA: state.selectedAssQA.map(x => ({
                ...x,
                selected: x.uuid === action.payload,
            })),
        };
    case events.MARK_ANSWER:
        return {
            ...state,
            selectedAssQA: setAnswer(state, action),
        };
    case events.ASS_UI_OVERVIEW:
        return {
            ...state,
            ui: { ...state.ui, overview: action.payload },
        };
    case events.ASS_UI_ZOOM:
        return {
            ...state,
            ui: { ...state.ui, zoom: action.payload },
        };
    case [events.SAVE_ASS_ANSWERS, '_PENDING'].join(''):
        return {
            ...state,
            isFinishAssessmentCompleted: 'PENDING',
        };
    case [events.FINISH_ASS, '_FULFILLED'].join(''):
        Tostify.showSuccess(i18Get('Assessment Finished'));
        window.location.hash = '/';
        return {
            ...initialState,
        };
    case events.CLEAR_ERROR:
        return {
            ...state,
            isFetchAssessmentUuid: false,
        };
    default:
        return state;
    }
}
