import eventNames from '../../actions/event-names';
import locations from '../../router/locations';

const initialState = {
    secondarySideBar: document.documentElement.clientWidth > 991,
    tertiarySideBar: document.documentElement.clientWidth > 991,
    favDrugList: false,
    assList: true,
    navigateTo: null,
    branding: null,
    selectedAssUuid: '',
    selectedAssKey: '',
    isDocumentHealth: false,
    isDocumentPain: false,
    isTriggered: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case eventNames.TOGGLE_SECONDARY_SIDEBAR:
        return {
            ...state,
            secondarySideBar: !state.secondarySideBar,
        };
    case eventNames.TOGGLE_TERTIARY_SIDEBAR:
        return {
            ...state,
            tertiarySideBar: !state.tertiarySideBar,
        };
    case eventNames.TOGGLE_FAVDRUGLIST:
        return {
            ...state,
            favDrugList: !state.favDrugList,
        };
    case eventNames.TOGGLE_ASSLIST:
        return {
            ...state,
            assList: !state.assList,
        };
    default:
        return state;
    }
}
