import React from 'react';

export default class Assessment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { size, color } = this.props;
        return (
            <svg width="123" height="122" viewBox="0 0 123 122" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="61.5" cy="60.8645" rx="61.5" ry="60.8645" fill="#FE4F0F" />
            <path d="M61.4984 13.2773C34.9879 13.2773 13.415 34.6235 13.415 60.8664C13.415 87.1092 34.9879 108.455 61.4984 108.455C88.0109 108.455 109.582 87.1092 109.582 60.8664C109.582 34.6235 88.0109 13.2773 61.4984 13.2773ZM61.4984 103.839C37.5509 103.839 18.0745 84.5601 18.0745 60.8664C18.0745 37.1727 37.5509 17.8938 61.4984 17.8938C85.4437 17.8938 104.922 37.1727 104.922 60.8664C104.922 84.56 85.4437 103.839 61.4984 103.839Z" fill="white" stroke="white" strokeWidth="0.5" />
            <path d="M51.3513 52.6308C51.3513 51.754 51.1246 51.1811 50.5988 50.9459C50.352 50.8356 50.0704 50.8167 49.7806 50.8444C49.4897 50.8722 49.1707 50.9491 48.8368 51.0491C48.5022 51.1493 48.1416 51.2762 47.7684 51.4077L47.7582 51.4113C47.3861 51.5424 46.9995 51.6786 46.6011 51.8027C45.8091 52.0494 44.9862 52.2431 44.1765 52.2494C44.1636 52.245 44.1506 52.2404 44.1377 52.2358C44.0203 52.194 43.9074 52.1475 43.7974 52.1013C43.7805 52.0942 43.7635 52.087 43.7465 52.0798C43.6557 52.0415 43.5648 52.0032 43.4807 51.9716C43.3815 51.9342 43.2764 51.9001 43.1749 51.8851C43.074 51.8702 42.9508 51.8701 42.8337 51.9291C42.7713 51.9606 42.7207 52.0037 42.6804 52.0543C41.9719 51.8686 41.3454 51.5813 40.7973 51.3298C40.5811 51.2307 40.3772 51.1371 40.1852 51.0576C39.9437 50.9576 39.7096 50.8758 39.4951 50.8457C39.2777 50.8152 39.0526 50.834 38.8583 50.9686C38.6668 51.1013 38.5489 51.3165 38.4774 51.583C38.4058 51.8498 38.373 52.1959 38.373 52.6308C38.373 56.1756 41.2867 59.0555 44.8622 59.0555C48.4376 59.0555 51.3513 56.1756 51.3513 52.6308ZM43.0325 52.6443C43.3712 52.7078 43.7262 52.7468 44.0958 52.7494C44.3274 52.8234 44.5866 52.8808 44.8622 52.8808C45.1889 52.8808 45.4926 52.8001 45.7545 52.7068C45.8857 52.6601 46.0093 52.6091 46.1207 52.5623C46.139 52.5545 46.157 52.547 46.1745 52.5396C46.2656 52.5012 46.3457 52.4674 46.4198 52.4395C46.509 52.406 46.5744 52.3869 46.6226 52.3797C46.6397 52.3772 46.6515 52.3767 46.6591 52.3768C46.6606 52.3801 46.6623 52.3842 46.6642 52.3895C46.6781 52.4285 46.6919 52.5023 46.6919 52.6308C46.6919 53.6244 45.8725 54.439 44.8622 54.439C43.8564 54.439 43.0399 53.6317 43.0325 52.6443Z" fill="white" stroke="white" strokeWidth="0.5" />
            <path d="M77.4399 51.5976C76.6117 51.5976 75.8596 51.44 75.1903 51.2508C74.8863 51.165 74.6016 51.0733 74.3329 50.9867C74.3052 50.9778 74.2776 50.9689 74.2502 50.9601C73.9602 50.8668 73.6854 50.7799 73.4356 50.72C72.9566 50.605 72.4623 50.5603 72.1072 50.8898C71.9344 51.0501 71.8208 51.277 71.7494 51.5593C71.678 51.8414 71.6445 52.1957 71.6445 52.6317C71.6445 56.1765 74.5582 59.0564 78.1336 59.0564C81.7091 59.0564 84.6228 56.1765 84.6228 52.6317C84.6228 51.7482 84.3961 51.1609 83.9036 50.8597C83.436 50.5738 82.8068 50.6017 82.1529 50.7169C81.7187 50.7933 81.231 50.9177 80.7227 51.0474C80.458 51.1149 80.1878 51.1838 79.9167 51.2482C79.1111 51.4393 78.2704 51.5976 77.4399 51.5976ZM78.1336 54.4399C77.1214 54.4399 76.3039 53.6254 76.3039 52.6317C76.3039 52.5032 76.3177 52.4293 76.3316 52.3903C76.3335 52.3851 76.3352 52.3809 76.3366 52.3777C76.3442 52.3776 76.3559 52.3782 76.3726 52.3806C76.4208 52.3878 76.4861 52.4068 76.5752 52.4404C76.6491 52.4682 76.7289 52.5019 76.8197 52.5402C76.8374 52.5477 76.8556 52.5553 76.8742 52.5632C76.9856 52.61 77.1092 52.661 77.2405 52.7077C77.5026 52.8011 77.8065 52.8817 78.1336 52.8817C78.4608 52.8817 78.7647 52.8011 79.0268 52.7077C79.1581 52.661 79.2817 52.61 79.393 52.5632C79.4117 52.5553 79.4299 52.5477 79.4476 52.5402C79.5384 52.5019 79.6182 52.4682 79.6921 52.4404C79.7812 52.4068 79.8465 52.3878 79.8946 52.3806C79.9114 52.3782 79.9231 52.3776 79.9307 52.3777C79.9321 52.3809 79.9338 52.3851 79.9357 52.3903C79.9496 52.4293 79.9633 52.5032 79.9633 52.6317C79.9633 53.6254 79.1459 54.4399 78.1336 54.4399Z" fill="white" stroke="white" strokeWidth="0.5" />
            <path d="M41.0464 79.9068L41.0462 79.907C40.1877 80.8581 40.2694 82.3182 41.2282 83.1677C42.1876 84.0182 43.6586 83.9354 44.5159 82.9878L44.516 82.9878C48.806 78.2435 54.996 75.5227 61.4985 75.5227C68.0011 75.5227 74.1911 78.2435 78.4811 82.9878C78.9408 83.498 79.5794 83.7555 80.2159 83.7555C80.7665 83.7555 81.3255 83.5624 81.7689 83.1678C82.7276 82.3182 82.8094 80.8581 81.9509 79.907L81.9507 79.9067C76.7761 74.1873 69.3233 70.9062 61.4985 70.9062C53.6759 70.9062 46.221 74.1852 41.0464 79.9068Z" fill="white" stroke="white" strokeWidth="0.5" />
        </svg>

        );
    }
}