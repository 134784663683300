import React from 'react';
import PropTypes from 'prop-types';

// import './button-group.css';

/* *********************************
Button group of more than one button  with multiple functions
type={ group, block }
width={nonflexible, flexible} default
********************************** */

export default class ButtonGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { children } = this.props;
        const classWidth = `btn-groupWrapper col-12 noPadding ${typeof this.props.flexible === 'undefined' ? 'nonflexible' : 'flexible'}`;
        const className = 'btn-group d-flex flex-row my-flex-container';
        return (
            <div className={classWidth}>
                <div className={className} role="group">
                    {children}
                </div>
            </div>
        );
    }
}

ButtonGroup.defaultProps = {
    type: 'group',
};
