import React, { Component } from 'react';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import { getTrans } from '../../../kado/utils';
import _ from 'lodash';
import { RadioButton } from '../../../kado/components';
import AssActions from '../../../actions/assessments';
import { ConfirmBox } from '../../../kado/utils/confirm-box';
import { i18Get } from '../../../i18';

import './ass-components.css';

class Options extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.ansChange = this.ansChange.bind(this);
    }
    ansChange(v) {
        this.props.dispatch(AssActions.ass.markAnswer({
            question: this.question.uuid,
            answer: v,
        }));

        const ans = _.find(this.props.assessments.selectedAssQA, { uuid: this.question.uuid });
        this.props.dispatch(AssActions.ass.documentQuestion(_.find(this.props.assessments.assList, 'selected').uuid, [ans.answer]));
    }
    isUnCheckAnswer(e,val){
        if(e=== val){
            ConfirmBox(('', i18Get('Are you sure you want to clear the selected answer?')), ()=>{this.unCheckAnswer()})
        }
    }
    unCheckAnswer(e){
        this.props.dispatch(AssActions.ass.markAnswer({
            question: this.question.uuid,
            answer: null,
        }));

        let ans = _.find(this.props.assessments.selectedAssQA, { uuid: this.question.uuid });
        this.props.dispatch(AssActions.ass.documentQuestion(_.find(this.props.assessments.assList, 'selected').uuid, [ans.answer]));
    }
    render() {
        const selectedAss = _.find(this.props.assessments.assList, 'selected');
        this.question = _.find(this.props.assessments.selectedAssQA, { uuid: this.props.question });
        const items = this.question.options.map(x => ({
            value: x.uuid,
            title: renderHTML(getTrans(x, 'title')),
        }));
        let rightAligned = this.question.option_style !== 's' && this.question.right_alignment;
        let checkbox = this.question.option_style !== 's' && this.question.check_box;
        return (
            <div className={`${rightAligned ? 'rightAligned':''} ${checkbox ? 'check_box':''}`}>
                <RadioButton
                    items={items}
                    onClick={(e)=>this.isUnCheckAnswer(e,this.question.answer.option)}
                    onChange={this.ansChange}
                    value={this.question.answer.option || null}
                    horizontal={this.question.option_style === 's'}
                    color="green"
                    block
                />
            </div>
        );
    }
}
export default connect(store => ({
    assessments: store.assessments,
    translations: store.translations,
}))(Options);
