import React from 'react';
import PropTypes from 'prop-types';
import './panel-palceholders.css';
import { Label } from '../../components';

export default class PanelPlaceholder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { children } = this.props;
        return (
                <div className="align-items-center d-flex col">
                    <div className=" col text-center panelPlaceholder">{children}</div>
                </div>

        );
    }
}
