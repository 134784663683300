// import { ToastContainer, toast } from 'react-toastify';
import { message, Button } from 'antd';

let showSuccess = (msg) => {
    message.success(msg);
};

let showInfo = (msg) => {
    message.info(msg);
};

let showError = (msg) => {
    message.error(msg);
};
export default {
    showSuccess, showInfo, showError,
};
