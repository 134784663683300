import React from 'react';
import { Checkbox } from 'antd';

import './checkbox-ant.css';

const CheckboxGroup = Checkbox.Group;

export default class CheckBoxAnt extends React.Component {
    constructor(props) {
        super(props);
        // this.state = {
        //     ...this.props,
        //     checked: this.props.checked || false,
        //     name: this.props.name,
        // };
        this.handleChange = this.handleChange.bind(this);
    }
    // componentWillReceiveProps(nextProps) {
    //     this.setState({
    //         ...this.props,
    //         checked: nextProps.checked || false,
    //     });
    // }
    handleChange(checkedValues) {
        this.props.onChange(checkedValues);
    }
    render() {
        const title = this.props.title;
        const block = this.props.block ? 'block' : '';
        const inverted = this.props.inverted ? 'inverted' : '';
        return (
            <div className={`kado-checkbox ${inverted} ${this.props.color} ${block}`}>
                <CheckboxGroup disabled={this.props.disabled} options={this.props.options} value={this.props.value} onChange={this.handleChange}>
                    {title}
                </CheckboxGroup>
            </div>
        );
    }
}
