


import React from 'react';
/* **********************
 Props:
 Size={}
 Color={}
 *********************** */

export default class OptionsIcon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        // const { color } = this.props;
        return (
            <svg width="7" height="26" viewBox="0 0 7 26" fill="none" class="options_svg">
                <circle cx="3.05882" cy="3.05882" r="3.05882" fill="#C4C4C4"></circle>
                <circle cx="3.05882" cy="13" r="3.05882" fill="#C4C4C4"></circle>
                <circle cx="3.05882" cy="22.9412" r="3.05882" fill="#C4C4C4"></circle>
            </svg>
        );
    }
}