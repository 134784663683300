import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Drawer, Menu, Dropdown } from 'antd';
import _ from 'lodash';
import Slider from 'react-rangeslider';
import './ass-components.css';
import AssActions from '../../../actions/assessments';
import I18, { i18Get } from '../../../i18';
import { getTrans } from '../../../kado/utils';
import { F0, F20, F40, F60, F80, F100, OptionsIcon, Close, AddIcon, Minus } from '../../../kado/images';


class Options extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            labels: {},
            reverse: false,
        };
        this.ansChange = this.ansChange.bind(this);
        this.sliderChange = this.sliderChange.bind(this);
        this.question = null;
    }

    onChangeComplete = () => {
        const ans = _.find(this.props.assessments.selectedAssQA, { uuid: this.question.uuid });
        this.props.dispatch(AssActions.ass.documentQuestion(_.find(this.props.assessments.assList, 'selected').uuid, [ans.answer]));
    }
    ansChange({ value }) {
        this.props.dispatch(AssActions.ass.markAnswer({
            question: this.question.uuid,
            answer: value,
        }));

        const ans = _.find(this.props.assessments.selectedAssQA, { uuid: this.question.uuid });
        this.props.dispatch(AssActions.ass.documentQuestion(_.find(this.props.assessments.assList, 'selected').uuid, [ans.answer]));
    }
    addSliderArrow() {
        setTimeout(function () {
            const div = document.createElement('div');
            div.className = 'slider-arrow';
            const arrowHandle = document.getElementsByClassName('rangeslider__handle')
            if (arrowHandle.length) {
                for (var i = 0; i < arrowHandle.length; i++) {
                    arrowHandle[i].appendChild(div)
                }
            }
        }, 500);
    }
    openSliderModal = () => {
        this.setState({
            visible: true
        }, () => {
            this.addSliderArrow()
        })
    }
    modalFooter = () => {
        return (
            <button className="btn-with-brand button kado-btn" onClick={this.onSave} style={{ marginRight: 8 }}>
                {i18Get('Save')}
            </button>
        )
    }
    onSave = () => {
        if(this.state.value === null || typeof(this.state.value) === 'undefined'){
            this.sliderChange(this.state.rangeFrom);
            this.onChangeComplete();
        }
        this.setState({
            visible: false
        })
    }
    onClose = () => {
        this.setState({
            visible: false
        })
    }
    restValue = () => {
        this.sliderChange(null);
        this.onChangeComplete();
    }
    renderTilte = (q) => {
        const menu = (
            <Menu className="user-options">
                <Menu.Item key="1">
                    <div onClick={this.restValue} >{i18Get('Reset')} </div>
                </Menu.Item>
            </Menu>
        )
        return (
            <div className="d-flex w-100 align-items-center justify-content-between quest-sliderheader">
                <span>
                    {/* {(getTrans(q, "title"))} */}
                </span>
                <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
                    <button className="icon-btn drawer-heading-btn">
                        <OptionsIcon />
                    </button>
                </Dropdown>
                <button onClick={this.onClose} className="icon-btn drawer-heading-btn">
                    <Close />
                </button>
            </div>
        )
    }
    sliderChange(value) {
        this.setState({
            value: value
        })
        this.props.dispatch(AssActions.ass.markAnswer({
            question: this.question.uuid,
            answer: value,
        }));
    }
    triggerPlusClick = () => {
        let currentValue = this.state.value;
        if(currentValue === 'undefined' || currentValue === null || currentValue === ''){
            currentValue = this.state.rangeFrom
        }
        let value = currentValue + this.state.stepInterval
        if (value > this.state.rangeTo) {
            value = this.state.rangeTo
        }
        this.sliderChange(value);
        this.onChangeComplete();
    }
    triggerMinusClick = () => {
        let currentValue = this.state.value;
        if(currentValue === 'undefined' || currentValue === null || currentValue === ''){
            currentValue = this.state.rangeFrom
        }
        let value = currentValue - this.state.stepInterval
        if (value < this.state.rangeFrom) {
            value = this.state.rangeFrom
        }
        this.sliderChange(value);
        this.onChangeComplete();
    }

    // sliderChange({ value }) {
    //     this.props.dispatch(AssActions.ass.markAnswer({
    //         question: this.question.uuid,
    //         answer: value,
    //     }));
    // }
    formatSlider = (value) => {
        return this.state.items[value-1].title
    }
    optionSliderChange = (value) => {
        this.props.dispatch(AssActions.ass.markAnswer({
            question: this.question.uuid,
            answer:  this.state.items[value - 1].value,
        }));
    }
    render() {
        const selectedAss = _.find(this.props.assessments.assList, 'selected');
        this.question = _.find(this.props.assessments.selectedAssQA, { uuid: this.props.question });
        const labels = {};
        this.state.value = this.question.answer.floatAns
        this.state.rangeFrom = this.question.config.rangeFrom
        this.state.rangeTo = this.question.config.rangeTo
        this.state.stepInterval = this.question.config.stepInterval
        let isAnswer = this.question.answer.floatAns >= 0 && this.question.answer.floatAns !== null && typeof(this.question.answer.floatAns) !== 'undefined'
        var {
            rangeFrom, rangeTo, stepInterval,
        } = this.question.config;

        const interval = (rangeTo - rangeFrom) / 10;
        for (let i = rangeFrom; i <= rangeTo; i += interval) {
            labels[Math.ceil(i)] = Math.ceil(i);
        }
        return (
            // <div className={`${selectedAss.documented || selectedAss.status === 'a' ? 'non-pointer' : ''}`}  >
            //     <div className={`d-flex justify-content-between ${this.question.smiley_start_good?'flex-row-reverse':''}`}>
            //         <F0 />
            //         <F20 />
            //         <F40 />
            //         <F60 />
            //         <F100 />
            //     </div>
            //     <Slider
            //         ref={this.rangeRef}
            //         min={rangeFrom}
            //         max={rangeTo}
            //         step={stepInterval}
            //         value={this.question.answer.floatAns || 0}
            //         labels={labels}
            //         onChange={value => this.sliderChange({ value })}
            //         onChangeComplete={this.onChangeComplete}
            //     />
            // </div>
            <React.Fragment>
                <div onClick={this.openSliderModal} className={`btn-group d-flex ${selectedAss.documented || selectedAss.status === 'a' ? 'non-pointer' : ''}`}>
                    <input
                        disabled
                        // value={this.props.answer ? this.props.answer.floatAns === null ? '' : this.props.answer.floatAns : null} 
                        value={(isAnswer)? this.question.answer.floatAns  :""}
                        placeholder={i18Get('Select the value')}
                        className="mb-0"
                        type="text"
                    />
                    <button className="kado-btn">
                        {i18Get('Edit')}
                    </button>
                </div>
                <Drawer
                    className="quest-slider"
                    title={this.renderTilte(this.props.question)}
                    placement="right"
                    closable={false}
                    onClose={this.onClose}
                    visible={this.state.visible}
                >
                    <React.Fragment>
                        <div className={`drawer-body-content w-100 numeric-slider ${selectedAss.documented || selectedAss.status === 'a' ? "disable_range_slider non-pointer" : "slider-option-wrap"}`}>
                            <div className={`d-flex justify-content-between quest-smiley ${this.question.smiley_start_good ? 'flex-column' : 'flex-column-reverse'}`}>
                                <F0 />
                                <F20 />
                                <F40 />
                                <F60 />
                                <F80 />
                                <F100 />
                            </div>
                            <div className="slider-btn-wrap">
                                <button onClick={this.triggerPlusClick} className="icon-btn circle with-bg">
                                    <AddIcon />
                                </button>
                                <button onClick={this.triggerMinusClick} className="icon-btn circle with-bg">
                                    <Minus />
                                </button>
                            </div>
                            <Slider className={`drawer-slider ${!this.question.smiley_start_good ? 'green-top' : 'green-bottom'}`}
                                // ref={this.rangeRef}
                                min={rangeFrom}
                                max={rangeTo}
                                step={stepInterval}
                                value={isAnswer? this.question.answer.floatAns :this.state.rangeFrom}
                                handleLabel={isAnswer? this.question.answer.floatAns :this.state.rangeFrom}
                                labels={labels}
                                onChange={this.sliderChange}
                                orientation="vertical"
                                onChangeComplete={this.onChangeComplete}
                            />
                        </div>
                        <div className="drawer-footer">
                            {this.modalFooter()}
                        </div>
                    </React.Fragment>
                </Drawer>
            </React.Fragment>
        );
    }
}
export default connect(store => ({
    assessments: store.assessments,
    translations: store.translations,
}))(Options);
