import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomProperties from 'react-custom-properties';
import { Offline } from 'react-detect-offline';
import { withRouter } from 'react-router';
import MainContainer from './main-container/main-container';
import Navbar from './navbar/navbar';
import I18 from '../../i18';
import { i18Get } from '../../i18';
import Page404 from '../404';

import './dashboard.css';
import '../../kado/css';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    is404() {
        return this.props.location.pathname === '/404';
    }
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        // document.addEventListener("fullscreenchange", this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({
            fullscreen:window.screen.height=== window.innerHeight
        });
    };

    render() {
        const colors = {
            '--grey-color': '#9e9e9e',
            '--greenDark-color': '#47a48a',
            '--green-color': '#44a48a',
            '--blue-color': '#4cb2e1',
            '--yellow-color': '#F6C913',
            '--orange-color': '#edb00a',
            '--red-color': '#ff4a52',
            '--majentha-color': '#f34482',
            '--white-color': '#FFF',
        };
        const greenColor = '#44a48a';
        return (
            <div className="container-fluid h-100">
                {this.props.translations.language === 'he' && 
                <link rel="stylesheet" type="text/css" href='/css/hebru.css' />}

                <CustomProperties global properties={colors}>
                    <div className="row h-100">
                        <div id="dashboard" className="w-100 d-flex flex-column dashboard-container noPadding">
                            {this.props.location.pathname === '/' ? '' : <Navbar />}
                            {!this.is404() && (
                                <div id="maincontainer" className={`mc-wrapper col p-0 ${(this.props.location.pathname === '/') && "login-mc"}`}>
                                    <MainContainer />
                                </div>
                            )}
                            {this.is404() && <Page404 />}
                        </div>
                    </div>
                </CustomProperties>
                {/* <Offline>
                    <div className="offline_toast">
                        <img src="/images/offline.svg" />
                        <I18 tkey="You are offline!" />
                    </div>
                </Offline> */}
                <style>
                    {`

                        svg.grey-i path {
                        fill: #9e9e9e ;
                    }
                    svg.orange-i path {
                    fill: #edb00a ;
                    }
                    svg.green-i path {
                    fill: ${greenColor} ;
                    }
                    svg.green-i polygon {
                    fill: ${greenColor} ;
                    }
                    .sidebarMiniIconcontainer.active,
                    .sidebarMiniIconcontainer:hover {
                        color: #ffffff;
                        background: ${greenColor};
                    }
                    .sidebarMiniIconcontainer.active svg {
                        fill: #fff;
                    }
                    .qstnr-wrapper:hover {
                    border: 1px solid ${greenColor} ;
                    }
                    .ass-tab-container .ass-tab.selected {
                        background-color: ${greenColor} ;
                        border: 1px solid ${greenColor} ;
                    }
                    .ant-dropdown-menu-submenu-title:hover {
                        background-color: ${greenColor} ;
                    }

                    .ant-dropdown-menu.navbar-profile .ant-dropdown-menu-item-active > a {
                    background-color: ${greenColor} ;
                    }
                    .ant-dropdown-menu.navbar-profile .ant-dropdown-menu-item:hover {
                    background-color: ${greenColor} ;
                    }
                    .diary-sb-link .text.active{
                        color: ${greenColor} ;
                    }
                    .sb-deptselect-div .Select-menu-outer {
                        border: 1px solid ${greenColor} ;
                    }
                    .sbs-header {
                        background-color: ${greenColor} ;
                    }
                    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
                        border-color: ${greenColor} ;
                    }
                    .checkbox .fa {
                    color: ${greenColor} ;
                    }
                    .kado-checkbox.green .ant-checkbox-inner,
                    .kado-checkbox.green .ant-checkbox:hover .ant-checkbox-inner,
                    .kado-checkbox.green .ant-checkbox-wrapper:hover .ant-checkbox-inner,
                    .kado-checkbox.green .ant-checkbox-input:focus + .ant-checkbox-inner {
                        border-color: ${greenColor} ;
                    }
                    .kado-checkbox.green .ant-checkbox-checked:after {
                        border: 1px solid ${greenColor} ;
                    }
                    .kado-checkbox.green .ant-checkbox-checked .ant-checkbox-inner, .ant-checkbox-indeterminate .ant-checkbox-inner {
                        background-color: ${greenColor} ;
                        border-color: ${greenColor} ;
                    }
                    .panel .kadoRadio.green .ant-checkbox-checked .ant-checkbox-inner:after{
                        border: 2px solid ${greenColor} !important;
                    
                    }
                    .kadoRadio.green .ant-checkbox-checked .ant-checkbox-inner:after{
                        border: 2px solid ${greenColor}  ;
                    }
                    .kado-confirm .ant-modal-footer .ant-btn:hover, 
                    .kado-confirm .ant-modal-footer .ant-btn:active, 
                    .kado-confirm .ant-modal-footer .ant-btn:focus {
                        color: ${greenColor} ;
                        border-color: ${greenColor} ;
                    } 
                    .kado-confirm .ant-btn-primary {
                        color: ${greenColor} ;
                        background-color: ${greenColor} ;
                        border: 1px solid ${greenColor} 
                    }
                    .kado-confirm .ant-modal-footer .ant-btn-primary,
                    .kado-confirm .ant-modal-footer .ant-btn-primary:hover,
                    .kado-confirm .ant-modal-footer .ant-btn-primary:active,
                    .kado-confirm .ant-modal-footer .ant-btn-primary:focus {
                        color: #ffffff;
                        background-color: ${greenColor} ;
                        border-color: ${greenColor} ;
                    }
                    .diaryDateContainer {
                        background-color: ${greenColor} ;
                    }
                    .react-datepicker-ignore-onclickoutside {
                        background-color: ${greenColor} ;
                    }
                    .react-datepicker__input-container input {
                        background-color: ${greenColor} ;
                    }
                    .react-datepicker__day--selected {
                        background-color: ${greenColor} ;
                    }
                    .react-datepicker__day--selected:hover {
                        background-color: ${greenColor} ;
                    }
                    .react-datepicker__day--keyboard-selected {
                        background-color: ${greenColor} ;
                    }
                    .kadoupload .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete{
                        color: ${greenColor} ;
                    }
                    .kado-notify .ant-notification-notice-icon-success {
                        color: ${greenColor} ;
                    }

                    .kadoRadio.green .ant-radio-checked .ant-radio-inner,
                    .kadoRadio.green .ant-radio-checked .ant-radio-inner:after,
                    .kadoRadio .ant-radio-wrapper:hover .ant-radio .ant-radio-inner{
                        border-color: ${greenColor} ;
                    }
                    /* round dot at center */
                    .kadoRadio.green .ant-radio-checked .ant-radio-inner:after{
                        background-color: ${greenColor} ;
                    }
                    .kadoRadio.green.inverted .ant-radio-inner:after{
                        background-color: #ffffff;
                    }

                    /* handles animation */
                    .kadoRadio.green .ant-radio-checked:after {
                        border: 1px solid ${greenColor} ;
                        background-color: ${greenColor} ;
                    }
                    .kadoRadio.green.inverted .ant-radio-checked:after {
                        background-color: ${greenColor} ;
                    }
                    .kadoRadio.green.inverted .ant-radio-checked .ant-radio-inner {
                        background-color: ${greenColor} ;
                    }
                    .kadoRadio .green-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner,
                    .kadoRadio .green-inverted.ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
                    .kadoRadio .green-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner{
                        border-color: ${greenColor} 
                    }
                    .kadoRadio .green-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner:after,
                    .kadoRadio.green .ant-radio-checked:after,
                    .kadoRadio .green-inverted.ant-radio-wrapper .ant-radio-checked:after {
                        background-color: ${greenColor} ;
                    }
                    .reminderButton {
                        background-color: ${greenColor} ;
                    }
                    .Select.is-open {
                        border-bottom: 1px solid ${greenColor} ;
                    }
                    .Select-clear-zone:hover {
                        color: #ff4a52;
                    }
                    .Select-option.is-selected {
                        background-color: ${greenColor} ;
                    }
                    .sidebarIconcontainer {
                        color: ${greenColor} ;
                    }
                    .sidebarMiniIconcontainer {
                        color: ${greenColor} ;
                    }
                    .ant-spin.ant-spin-lg.ant-spin-spinning.ant-spin-show-text {
                        color: ${greenColor} ;
                    }
                    .ant-spin.ant-spin-lg.ant-spin-spinning.ant-spin-show-text .ant-spin-dot i {
                        background-color: ${greenColor} ;
                    }
                    .ant-calendar-today .ant-calendar-date {
                        border-color: ${greenColor} ;
                    }

                    .ant-calendar-selected-day.ant-calendar-today .ant-calendar-date,
                    .ant-calendar-selected-day .ant-calendar-date {
                        background: ${greenColor} ;
                    }
                    .ant-calendar-date:hover,
                    .ant-calendar-selected-day .ant-calendar-date:hover {
                        background: ${greenColor} ;
                        opacity: 0.8;
                    }

                    .ant-calendar .ant-calendar-ok-btn {
                        background-color: ${greenColor} ;
                        border-color: ${greenColor} ;
                    }

                    .ant-calendar .ant-calendar-ok-btn:hover {
                        background-color: ${greenColor} ;
                        border-color: ${greenColor} ;
                        opacity: 0.8;
                    }
                    .ant-calendar-picker-icon:after{
                        color: ${greenColor} ;
                    }
                    .ant-input:focus{
                        border-color: ${greenColor} ;
                    }
                    .ant-switch,
                    .ant-switch-checked{
                        background-color: ${greenColor} ;
                    }
                    .ant-slider-handle,
                    .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open),
                    .ant-slider:hover .ant-slider-handle.ant-tooltip-open {
                        border-color: ${greenColor}  ;
                    }
                    .ant-slider-track,
                    .ant-slider:hover .ant-slider-track {
                        background-color: ${greenColor} ;
                    }
                    .ant-calendar-time-picker-select li:hover,
                    .ant-time-picker-panel-select li:hover,
                    .ant-time-picker-panel-addon button:hover
                    .ant-time-picker-panel-addon button:focus{
                        background-color: ${greenColor} ;
                        color:#ffffff;
                    }
                    .ant-btn-primary,
                    .ant-time-picker-panel-addon button:hover{
                        border: 1px solid ${greenColor} ;
                    }
                    .ant-time-picker-panel-addon .ant-btn-sm{
                        border-radius: 0px;
                    }
                    /* selected option */
                    li.ant-calendar-time-picker-select-option-selected,
                    li.ant-time-picker-panel-select-option-selected{
                        background-color:${greenColor} ;
                    }
                    .ant-time-picker-panel-select-active li.ant-time-picker-panel-select-option-selected,
                    .ant-calendar-time-picker-select-active li.ant-calendar-time-picker-select-option-selected {
                        background-color: ${greenColor} ;
                    }
                    .green-inverted .sbs-EntryIcon svg {
                        fill:${greenColor} ;
                    }
                    .ant-btn.ant-btn-primary{
                        background-color:${greenColor}  ;
                        border-color: ${greenColor}  ;    
                    }
                    .react-confirm-alert .react-confirm-alert-button-group button:nth-of-type(2) {
                        background: ${greenColor} ;
                    }
                    input[type="text"]:disabled,
                    input:focus:not([readonly]),
                    input[type="text"]:focus:not([readonly]),
                    input[type="password"]:focus:not([readonly]),
                    input[type="email"]:focus:not([readonly]),
                    input[type="url"]:focus:not([readonly]),
                    input[type="time"]:focus:not([readonly]),
                    input[type="date"]:focus:not([readonly]),
                    input[type="datetime-local"]:focus:not([readonly]),
                    input[type="tel"]:focus:not([readonly]),
                    input[type="number"]:focus:not([readonly]),
                    input[type="search-md"]:focus:not([readonly]),
                    input[type="search"]:focus:not([readonly]),
                    textarea.md-textarea:focus:not([readonly]) {
                    border-bottom: 1px solid ${greenColor} ;
                    }
                    input:hover:not([readonly]),
                    input[type="text"]:hover:not([readonly]),
                    input[type="password"]:hover:not([readonly]),
                    input[type="email"]:hover:not([readonly]),
                    input[type="url"]:hover:not([readonly]),
                    input[type="time"]:hover:not([readonly]),
                    input[type="date"]:hover:not([readonly]),
                    input[type="datetime-local"]:hover:not([readonly]),
                    input[type="tel"]:hover:not([readonly]),
                    input[type="number"]:hover:not([readonly]),
                    input[type="search-md"]:hover:not([readonly]),
                    input[type="search"]:hover:not([readonly]),
                    textarea.md-textarea:hover:not([readonly]) {
                    border-bottom: 1px solid ${greenColor} ;
                    }
                    ::-webkit-scrollbar-thumb:hover {
                        background: ${greenColor}; 
                    }
                    /* Handle */
                    ::-webkit-scrollbar-thumb {
                        background: ${greenColor};
                    }
                    .kado-confirm .ant-btn-primary {
                        border: 1px solid ${greenColor};
                    }
                    .panel .kadoRadio.green .ant-checkbox-checked .ant-checkbox-inner:after{
                        border: 2px solid ${greenColor} !important;

                    }
                    .kadoRadio.green .ant-checkbox-checked .ant-checkbox-inner:after{
                        border: 2px solid ${greenColor} !important;

                    }
                    .ant-btn.ant-btn-primary{
                        background-color: ${greenColor} !important;
                    }
                    .ant-radio-checked .ant-radio-inner,
                    .ant-radio-checked .kadoRadio .ant-radio-inner,
                    .ant-btn.ant-btn-primary{
                        border-color:${greenColor} !important;
                    }
                    .ant-radio-inner::after,
                    .btn-with-brand.green {
                        background-color: ${greenColor} !important;
                    }
                `}
                </style>

                {this.props.user.hijacked && this.props.user.assist_details && typeof this.props.user.assist_details !== 'undefined' && this.props.user.assist_details.readWriteMode === false &&
                    <style>
                        {`
                    button {
                        pointer-events: none;
                        opacity: 0;
                    }
                    button.btn-fnf-active {
                        pointer-events: auto;
                        opacity: initial;
                        opacity: 0;
                    } 
                    .ant-modal.download_modal button {
                        pointer-events: auto;
                        opacity: initial;
                        opacity: 0;
                    }
                    .diaryActionsBox {
                        pointer-events: none;
                        opacity: 0;
                    }
                    .kadoupload {
                        pointer-events: none;
                    }
                    .sb-fav-drug-btn button {
                        opacity: 0.8;
                    }
                    .image-preview-actions button {
                        opacity: 1;
                    }
                    .image-preview-actions .button.undefined {
                        display: none;
                    }
                    .file-field-box {
                        pointer-events: none;
                    }
                    .messages button.msg-read {
                        opacity: 1;
                    }
                    .ant-modal.download_modal button {
                        opacity: 1;
                    }
                    .ass-nav-btn button {
                        opacity: 1;
                    }
                    .ass-ovrview-qst-sec {
                        pointer-events: none;
                    }
                    .mc-content-div {
                        pointer-events: none;
                    }
                    .reminder-page {
                        pointer-events: none;
                    }
                    .pers-detail {
                        pointer-events: none;
                    }
                    .basic-sett {
                        pointer-events: none;
                    }
                    .time-sett {
                        pointer-events: none;
                    }
                    .kado-date-picker {
                        pointer-events: none;
                    }
                    .ass-zoom {
                        pointer-events: none;
                    }
                    .files-qn {
                        pointer-events: none;
                    }
                    .myfilesWrapper {
                        pointer-events: none;
                    }
                    #root {
                    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
                    -moz-user-select: none; /* mozilla browsers */
                    -khtml-user-select: none; /* webkit (konqueror) browsers */
                    -ms-user-select: none; /* IE10+ */
                    }
                    `}
                    </style>
                }
            </div>
        );
    }
}
Dashboard.propTypes = {};
export default withRouter(connect(store => ({
    user: store.user,
    translations : store.translations
}))(Dashboard));
