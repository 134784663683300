import axios from 'axios';
import events from '../event-names';
import Config from '../../cankado-config';


const formData = new FormData();

export default () => ({
    type: events.CHECK_LOGIN,
    payload: axios.post([Config.apiRoot, '/check/valid/user/'].join(''), formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'z-sec-fetch': Config.ci1 + Config.ci2,
        },
    }),
});
