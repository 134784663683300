import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import AssActions from '../../actions/assessments';
import { ClearNavigateTo } from '../../actions/ui';
import SingleQuestionView from './ass-single-question-view';
import Overview from './ass-questionnaire-overview';
import { PanelPlaceholder } from '../../kado/components';
import I18, { i18Get } from '../../i18';
import SpinnerAnt from '../../kado/components/spinner-ant';
import './ass-components.css';

class AssessmentKey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key: this.props.match.params.key,
            uuid: this.props.match.params.uuid,
        };
    }
    componentDidMount() {
        if (typeof this.state.key !== 'undefined') {
            this.props.dispatch(AssActions.key.fetch(this.state.key));
        } else {
            this.props.dispatch(AssActions.ass.fetch(this.state.uuid));
        }
    }
    render() {

        const language = this.props.translations.language;
        if (this.props.ui.navigateTo) {
            this.props.history.push(this.props.ui.navigateTo);
            this.props.dispatch(ClearNavigateTo());
        }
        const ass = this.props.match.params.key
            ? _.find(this.props.assessments.assList, x => x.accessKey && x.accessKey.accesskey === this.props.match.params.key)
            : _.find(this.props.assessments.assList, { uuid: this.props.match.params.uuid });
        if (!ass || !ass.questionnaires) {
            return <PanelPlaceholder> <SpinnerAnt /> </PanelPlaceholder>;
        }
        const selAss = _.find(this.props.assessments.assList, 'selected');
        if (!selAss) {
            return <PanelPlaceholder> <SpinnerAnt /> </PanelPlaceholder>;
        }
        if(this.props.assessments.isAnswerFetchCompleted === 'PENDING') {
            return <SpinnerAnt />
        }
        const qList = selAss.questionnaires;
        if (!_.find(qList, 'selected') && qList.length) this.props.dispatch(AssActions.ass.selectQuestionnaire(qList[0].uuid));
        return this.props.assessments.ui.overview ? <Overview /> : <SingleQuestionView />;
    }
}
AssessmentKey.propTypes = {};

export default withRouter(connect(store => ({
    assessments: store.assessments,
    translations: store.translations,
    ui: store.ui,
}))(AssessmentKey));
