import _ from 'lodash';
import store from '../../store';

function getTrans(obj, key, decode = true) {
    const lang = store.getState().translations.language;
    let Trans;
    if (!_.isArray(obj.translations)) {
        Trans = obj.translations;
    }
    Trans = _.find(obj.translations, o => o.language === lang);
    const translated = Trans && key in Trans && Trans[key] ? Trans[key] : obj[key];
    // if (decode) {
    //     return entities.decode(translated);
    // }
    return translated;
}
export default getTrans;
