import moment from 'moment';

const dateFormat = 'DD.MM.YYYY';
const timeFormat = 'HH:mm';
const dateTimeFormat = [dateFormat, timeFormat].join(' ');
export default {
    deFormatDate(dString = null) {
        if (!dString) return moment().format('YYYY-MM-DD');
        return moment(dString, dateFormat).format('YYYY-MM-DD');
    },

    formatDateStd(dString = null) {
        if (!dString) return moment().format('YYYY-MM-DD');
        return moment(dString).format('YYYY-MM-DD');
    },

    formatDate(dString = null) {
        if (!dString) return moment().format(dateFormat);
        return moment(dString, 'YYYY-MM-DD').format(dateFormat);
    },

    formatDateISODateTime(dString = null) {
        if (!dString) return moment().format(dateFormat);
        return moment(dString).format(dateFormat);
    },

    formatTime(dString = null) {
        if (!dString) return moment().format(timeFormat);
        return moment(dString).format(timeFormat);
    },
    formatDateTime(dString = null) {
        if (!dString) return moment().format(dateTimeFormat);
        return moment(dString).format(dateTimeFormat);
    },
    deFormatISODateTime(dString, tString) {
        const d = moment(`${dString}T${tString}`, `${dateFormat}T${timeFormat}`);
        return d.isValid() ? d.format() : null;
    },
};
