import React from 'react';
import { Checkbox } from 'antd';
import './checkbox-ant.css';

export default class CheckBoxAnt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props,
            checked: this.props.checked || false,
            name: this.props.name,
            color:this.props.color,
        };
        this.handleChange = this.handleChange.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            ...this.props,
            checked: nextProps.checked || false,
        });
    }
    handleChange(e) {
        this.setState({checked: e.target.checked},
            () => {
                this.props.onChange(this.state);
            },
        );
    }
    render() {
        const title = this.props.title;
        const inline = this.props.inline ? 'd-inline' : '';
        const inverted = this.props.inverted ? 'inverted' : '';
        const className = `kado-checkbox ${this.state.color} ${inverted} ${inline}`;
        return (
            <div className={className}>
                <Checkbox disabled={this.props.disabled} checked={this.state.checked} onChange={this.handleChange}>
                    {title}
                </Checkbox>
            </div>
        );
    }
}
