import React from 'react';

export default class Assessment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { size, color } = this.props;
        return (
            <svg width="123" height="122" viewBox="0 0 123 122" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="61.5" cy="60.8645" rx="61.5" ry="60.8645" fill="#FD0606"/>
                <path d="M61.4984 13.2744C34.9879 13.2744 13.415 34.6206 13.415 60.8635C13.415 87.1063 34.9879 108.452 61.4984 108.452C88.0109 108.452 109.582 87.1063 109.582 60.8635C109.582 34.6206 88.0109 13.2744 61.4984 13.2744ZM61.4984 103.836C37.5509 103.836 18.0745 84.5571 18.0745 60.8635C18.0745 37.1698 37.5509 17.8909 61.4984 17.8909C85.4437 17.8909 104.922 37.1698 104.922 60.8635C104.922 84.5571 85.4437 103.836 61.4984 103.836Z" fill="white" stroke="white" stroke-width="0.5"/>
                <path d="M51.3513 52.6292C51.3513 52.4095 51.3401 52.2249 51.3158 52.0751C51.2923 51.9304 51.2529 51.7929 51.1789 51.6876C51.0939 51.5666 50.9632 51.4908 50.8044 51.5009C50.6718 51.5093 50.5542 51.5764 50.4623 51.6438C50.2747 51.7814 50.0629 52.0208 49.8397 52.2971C49.6756 52.5002 49.4945 52.7374 49.301 52.9906C49.2269 53.0876 49.151 53.187 49.0735 53.2877C48.5087 54.0222 47.8491 54.8458 47.123 55.4856C46.3929 56.1287 45.6309 56.5542 44.8622 56.5542C44.0934 56.5542 43.3315 56.1287 42.6014 55.4856C41.8752 54.8458 41.2156 54.0222 40.6509 53.2877C40.5734 53.187 40.4975 53.0876 40.4233 52.9905C40.2299 52.7374 40.0487 52.5002 39.8846 52.2971C39.6614 52.0208 39.4496 51.7814 39.262 51.6438C39.1702 51.5764 39.0525 51.5093 38.9199 51.5009C38.7611 51.4908 38.6304 51.5666 38.5455 51.6876C38.4715 51.7929 38.432 51.9304 38.4086 52.0751C38.3842 52.2249 38.373 52.4095 38.373 52.6292C38.373 56.1741 41.2867 59.0539 44.8622 59.0539C48.4376 59.0539 51.3513 56.1741 51.3513 52.6292Z" fill="white" stroke="white" stroke-width="0.5"/>
                <path d="M38.4533 61.7074C37.9608 62.8713 36.9061 63.5432 36.0975 63.2081C35.2889 62.8729 35.0327 61.6577 35.5253 60.4938C36.0178 59.3298 37.7414 57.0773 38.55 57.4124C39.3585 57.7476 38.9459 60.5434 38.4533 61.7074Z" fill="white"/>
                <path d="M34.7746 70.4007C34.2821 71.5646 33.2273 72.2365 32.4188 71.9014C31.6102 71.5663 31.354 70.3511 31.8466 69.1871C32.3391 68.0232 34.0627 65.7707 34.8713 66.1058C35.6798 66.4409 35.2672 69.2368 34.7746 70.4007Z" fill="white"/>
                <path d="M31.0949 79.095C30.6024 80.259 29.5477 80.9309 28.7391 80.5958C27.9305 80.2606 27.6743 79.0454 28.1669 77.8815C28.6594 76.7175 30.383 74.465 31.1916 74.8001C32.0001 75.1353 31.5875 77.9311 31.0949 79.095Z" fill="white"/>
                <path d="M88.4272 60.9122C88.7782 62.2084 88.3327 63.4526 87.4323 63.6914C86.5319 63.9302 85.5176 63.073 85.1666 61.7769C84.8157 60.4808 84.7846 57.4764 85.685 57.2376C86.5854 56.9988 88.0763 59.6161 88.4272 60.9122Z" fill="white"/>
                <path d="M91.0473 70.5919C91.3983 71.8881 90.9528 73.1323 90.0525 73.3711C89.1521 73.6099 88.1377 72.7527 87.7868 71.4566C87.4358 70.1605 87.4047 67.1561 88.3051 66.9173C89.2055 66.6785 90.6964 69.2958 91.0473 70.5919Z" fill="white"/>
                <path d="M93.6694 80.2736C94.0203 81.5697 93.5749 82.814 92.6745 83.0527C91.7741 83.2915 90.7597 82.4343 90.4088 81.1382C90.0579 79.8421 90.0268 76.8377 90.9272 76.5989C91.8276 76.3602 93.3185 78.9774 93.6694 80.2736Z" fill="white"/>
                <path d="M78.1327 56.5542C77.3639 56.5542 76.602 56.1287 75.8719 55.4856C75.1457 54.8458 74.4861 54.0222 73.9214 53.2877C73.8439 53.187 73.768 53.0876 73.6938 52.9905C73.5004 52.7373 73.3192 52.5002 73.1551 52.2971C72.9319 52.0208 72.7202 51.7814 72.5325 51.6438C72.4407 51.5764 72.3231 51.5093 72.1904 51.5009C72.0316 51.4908 71.9009 51.5666 71.816 51.6875C71.742 51.7929 71.7025 51.9304 71.6791 52.0751C71.6547 52.2249 71.6436 52.4095 71.6436 52.6292C71.6436 56.174 74.5572 59.0539 78.1327 59.0539C81.7081 59.0539 84.6218 56.174 84.6218 52.6292C84.6218 52.4095 84.6106 52.2249 84.5863 52.0751C84.5628 51.9304 84.5234 51.7929 84.4494 51.6875C84.3644 51.5666 84.2337 51.4908 84.0749 51.5009C83.9423 51.5093 83.8246 51.5764 83.7328 51.6438C83.5452 51.7814 83.3334 52.0208 83.1102 52.2971C82.9461 52.5002 82.7649 52.7374 82.5715 52.9906C82.4974 53.0876 82.4214 53.187 82.344 53.2877C81.7792 54.0222 81.1196 54.8458 80.3934 55.4856C79.6634 56.1287 78.9014 56.5542 78.1327 56.5542Z" fill="white" stroke="white" stroke-width="0.5"/>
                <path d="M41.0464 79.9068L41.0462 79.907C40.1877 80.8581 40.2694 82.3182 41.2282 83.1677C42.1876 84.0182 43.6586 83.9354 44.5159 82.9878L44.516 82.9878C48.806 78.2435 54.996 75.5227 61.4985 75.5227C68.0011 75.5227 74.1911 78.2435 78.4811 82.9878C78.9408 83.498 79.5794 83.7555 80.2159 83.7555C80.7665 83.7555 81.3255 83.5624 81.7689 83.1678C82.7276 82.3182 82.8094 80.8581 81.9509 79.907L81.9507 79.9067C76.7761 74.1873 69.3233 70.9062 61.4985 70.9062C53.6759 70.9062 46.221 74.1852 41.0464 79.9068Z" fill="white" stroke="white" stroke-width="0.5"/>
                </svg>
        );
    }
}