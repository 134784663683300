import fetch from './fetch';
import keyFetch from './key/fetch';
import {
    selectQstnr,
    selectQstn,
    markAnswer,
    setZoom,
    setOverview,
} from './ass/common';
import finish from './ass/finish';
import fetchAss from './ass/fetch';
import documentQuestion from './ass/documentQuestion';

export default {
    fetch,
    key: {
        fetch: keyFetch,
    },
    ass: {
        selectQuestionnaire: selectQstnr,
        selectQuestion: selectQstn,
        markAnswer,
        finish,
        documentQuestion,
        setZoom,
        setOverview,
        fetch: fetchAss,
    },
};
