import axios from 'axios';
import Config from '../../../cankado-config';
import events from '../../event-names';
import { apiHeaders, datetime } from '../../../kado/utils/';

export default uuid => dispatch =>
    dispatch({
        type: events.FETCH_ASS,
        meta: uuid,
        payload: axios.get(`${Config.apiRoot}/patient/assessments/${uuid}/`, { ...apiHeaders() }),
    }).then(({ value, action }) => {
        if (action.type === `${events.FETCH_ASS}_FULFILLED`) {
            dispatch({
                type: events.FETCH_ASS_ANSWER,
                payload: axios.get(`${Config.apiRoot}/patient/assessments/${action.payload.data.uuid}/answers/`, { ...apiHeaders() }),
            });
        }
    });
