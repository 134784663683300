import axios from 'axios';
import Config from '../../../cankado-config';
import events from '../../event-names';
import { apiHeaders } from '../../../kado/utils/';

export default (ass, answers) => dispatch =>
    dispatch({
        type: events.SAVE_ASS_ANSWERS,
        payload: axios.patch(`${Config.apiRoot}/patient/assessments/${ass}/answers/`, [...answers], { ...apiHeaders() }),
    }).then(({ value, action }) => {
        if (action.type === `${events.SAVE_ASS_ANSWERS}_FULFILLED`) {
            dispatch({
                type: events.FINISH_ASS,
                payload: axios.patch(
                    `${Config.apiRoot}/patient/assessments/${ass}/`,
                    {
                        uuid: ass,
                        documented: true,
                    },
                    { ...apiHeaders() },
                ),
            });
        }
    });
