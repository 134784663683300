import React from 'react';
/* **********************
 Props:
 Size={}
 Color={}
 *********************** */

export default class AddIcon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { selected } = this.props;
        return (
            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="0.923523" y1="17.113" x2="34.7697" y2="17.113" stroke="#8B8C93" strokeWidth="3" />
                <line x1="17.0899" y1="0.748291" x2="17.0899" y2="34.2447" stroke="#8B8C93" strokeWidth="3" />
            </svg>
        );
    }
}