import React from 'react';

export default class Assessment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { size, color } = this.props;
        return (
            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="13.5" cy="13.5" r="13.5" fill="#FFC123" />
                <path d="M13.5 2.75C7.57232 2.75 2.75 7.57232 2.75 13.5C2.75 19.4277 7.57232 24.25 13.5 24.25C19.4281 24.25 24.25 19.4277 24.25 13.5C24.25 7.57233 19.4281 2.75 13.5 2.75ZM13.5 22.837C8.35155 22.837 4.16304 18.6485 4.16304 13.5C4.16304 8.35155 8.35155 4.16304 13.5 4.16304C18.648 4.16304 22.837 8.35156 22.837 13.5C22.837 18.6484 18.648 22.837 13.5 22.837Z" fill="white" stroke="white" strokeWidth="0.5" />
                <path d="M11.4674 11.6739C11.4674 10.7808 10.741 10.0544 9.84784 10.0544C8.95468 10.0544 8.22827 10.7808 8.22827 11.6739C8.22827 12.5671 8.95468 13.2935 9.84784 13.2935C10.741 13.2935 11.4674 12.5671 11.4674 11.6739ZM9.64131 11.6739C9.64131 11.5604 9.73436 11.4674 9.84784 11.4674C9.96131 11.4674 10.0544 11.5604 10.0544 11.6739C10.0544 11.7874 9.96131 11.8804 9.84784 11.8804C9.73436 11.8804 9.64131 11.7874 9.64131 11.6739Z" fill="white" stroke="white" strokeWidth="0.5" />
                <path d="M17.1523 10.0544C16.2591 10.0544 15.5327 10.7808 15.5327 11.6739C15.5327 12.5671 16.2591 13.2935 17.1523 13.2935C18.0454 13.2935 18.7718 12.5671 18.7718 11.6739C18.7718 10.7808 18.0454 10.0544 17.1523 10.0544ZM17.1523 11.8804C17.0385 11.8804 16.9458 11.7876 16.9458 11.6739C16.9458 11.5603 17.0385 11.4674 17.1523 11.4674C17.266 11.4674 17.3588 11.5603 17.3588 11.6739C17.3588 11.7876 17.266 11.8804 17.1523 11.8804Z" fill="white" stroke="white" strokeWidth="0.5" />
                <path d="M18.3263 17.9673L18.5762 17.9673L18.5763 17.7174C18.5763 17.6001 18.5763 17.4606 18.5763 17.322C18.5763 17.1018 18.5763 16.8839 18.5764 16.7612L18.5766 16.511L18.3264 16.511L17.4129 16.511L13.9131 16.511C13.1944 16.511 12.5931 16.5001 11.9962 16.4892L11.9793 16.4889C11.3883 16.4781 10.8003 16.4673 10.1086 16.4673L9.85953 16.4673L9.71236 16.4673C9.55077 16.4673 9.43195 16.5664 9.36574 16.6545C9.2957 16.7477 9.25 16.8705 9.25 17L9.24997 17.3831L9.24994 17.7173L9.24994 17.9673L9.49994 17.9673L13.9131 17.9673L18.3263 17.9673Z" fill="white" stroke="white" strokeWidth="0.5" />
            </svg>
        );
    }
}