import React from 'react';

export default class Input extends React.Component {
    componentWillReceiveProps(nextProps) {
        this.setState({
            ...nextProps,
        });
    }
    render() {
        return (
            <div className="inputContainer">
                <input {...this.props} onChange={this.props.onChange} autoComplete="off" value={this.props.value} name={this.props.name} placeholder={this.props.placeholder} />
            </div>
        );
    }
}
