import eventNames from '../../actions/event-names';
import configFile from '../../cankado-config';
import languageList from '../../kado/constant-files/language-options';

var language = 'en'
function getDefaultLanguage() {
    var userLnag = navigator.language || navigator.userLanguage || language;
    let ourLang = languageList.options.map( (x) => {
        x = x.value
        return x
    })
    if(userLnag){
    language = userLnag.split('-')[0]
        if (ourLang.indexOf(language) > -1){      
            if(language === 'el') language = 'gr'
            return language
        } else {
            language = 'en';
        }
    }
    return language;
};

const initialTranslationsState = {
    isTranslationFetchCompleted: false,
    language: getDefaultLanguage(),
    data: { de: {}, ml: {}, dev: {} },
};

export default function reducer(state = initialTranslationsState, action) {
    switch (action.type) {
    // TRANSLATION
    case eventNames.TRANSLATION_FETCH_PENDING:
        return {
            ...state,
            isTranslationFetchCompleted: 'PENDING',
        };
    case 'TRANSLATION_FETCH_FULFILLED':
        state.data[action.payload.language] = action.payload.data;
        return {
            ...state,
            isTranslationFetchCompleted: 'FULFILLED',
        };
    case eventNames.TRANSLATION_FETCH_REJECTED:
        return {
            ...state,
            isTranslationFetchCompleted: 'REJECTED',
        };
    case eventNames.RESET_TRANSLATION_LANGUAGE:
        document.cookie = 'i18next=' + action.language + configFile.cookieDomain + '; expires=Thu, 18 Dec 2019 12:00:00 UTC';
        return {
            ...state,
            language: action.language,
        };
    case `${eventNames.PATIENT_USERPROFILE_EDIT}_FULFILLED`:
        return {
            ...state,
            language: action.payload.data.patient.language,
        };

    default:
        return state;
    }
}
