import React from 'react';
import { Route, Switch, HashRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { history } from '../store';
import locations from './locations';
import Config from '../cankado-config';

import Dashboard from '../components/dashboard';
import CheckLogin from '../components/auth/check-login';

class routes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        if (!this.props.user.isAuthenticated) {
            return <CheckLogin />;
        }

        return (
            <HashRouter history={history} basename="">
                <Switch>
                    <Route path={locations.dashboard} component={Dashboard} />
                </Switch>
            </HashRouter>
        );
    }
}

export default connect(store => ({
    user: store.user,
}))(routes);
