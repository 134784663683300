import axios from 'axios';
import moment from 'moment';
import Config from '../../../cankado-config';
import events from '../../event-names';
import { apiHeaders, datetime } from '../../../kado/utils/';

export default key => dispatch =>
    dispatch({
        type: events.FETCH_ASS_KEY,
        meta: key,
        payload: axios.get(`${Config.apiRoot}/patient/assessments/keys/${key}/?time=${moment().format()}`, { ...apiHeaders() }),
    });
    // .then(({ value, action }) => {
    //     if (action.type === `${events.FETCH_ASS_KEY}_FULFILLED`) {
    //         dispatch({
    //             type: events.FETCH_ASS,
    //             payload: axios.get(`${Config.apiRoot}/patient/assessments/${action.payload.data.uuid}/`, { ...apiHeaders() }),
    //         });
    //         dispatch({
    //             type: events.FETCH_ASS_ANSWER,
    //             payload: axios.get(`${Config.apiRoot}/patient/assessments/${action.payload.data.uuid}/answers/`, { ...apiHeaders() }),
    //         });
    //     }
    // });
