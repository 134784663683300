


import React from 'react';
/* **********************
 Props:
 Size={}
 Color={}
 *********************** */

export default class CloseIcon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        // const { color } = this.props;
        return (
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.6806 18.4615L11.6836 10.4644L19.6806 2.46737L17.997 0.783784L10 8.78082L2.00296 0.783784L0.319371 2.46737L8.31641 10.4644L0.319371 18.4615L2.00296 20.145L10 12.148L17.997 20.145L19.6806 18.4615Z" fill="#585859" />
            </svg>

        );
    }
}