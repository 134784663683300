import React from 'react';

export default class Assessment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { size, color } = this.props;
        return (
            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="13.5" cy="13.5" r="13.5" fill="#18AF79" />
                <path d="M13.5 2.75C7.57232 2.75 2.75 7.57232 2.75 13.5C2.75 19.4277 7.57232 24.25 13.5 24.25C19.4281 24.25 24.25 19.4277 24.25 13.5C24.25 7.57233 19.4281 2.75 13.5 2.75ZM13.5 22.837C8.35155 22.837 4.16304 18.6485 4.16304 13.5C4.16304 8.35155 8.35155 4.16304 13.5 4.16304C18.648 4.16304 22.837 8.35156 22.837 13.5C22.837 18.6484 18.648 22.837 13.5 22.837Z" fill="white" stroke="white" strokeWidth="0.5" />
                <path d="M11.4674 11.6739C11.4674 11.4543 11.4203 11.2569 11.2853 11.1131C11.1499 10.969 10.9699 10.9258 10.8136 10.9141C10.6589 10.9026 10.4836 10.9195 10.321 10.9353C10.3143 10.936 10.3075 10.9366 10.3008 10.9373C10.1619 10.9509 10.0234 10.9644 9.88239 10.9682C9.87094 10.9677 9.85942 10.9674 9.84784 10.9674C9.83047 10.9674 9.81325 10.968 9.79619 10.9693C9.62976 10.9686 9.47166 10.9524 9.31615 10.9365C9.31266 10.9361 9.30917 10.9358 9.30568 10.9354L9.30185 10.935C9.14946 10.9194 8.98396 10.9025 8.8389 10.9143C8.6878 10.9265 8.51459 10.973 8.38813 11.1236C8.26723 11.2676 8.22827 11.4612 8.22827 11.6739C8.22827 12.5671 8.95468 13.2935 9.84784 13.2935C10.741 13.2935 11.4674 12.5671 11.4674 11.6739ZM9.82024 11.4692C9.83703 11.4692 9.85375 11.469 9.8704 11.4686C9.97339 11.48 10.0544 11.5681 10.0544 11.6739C10.0544 11.7874 9.96131 11.8804 9.84784 11.8804C9.73436 11.8804 9.64131 11.7874 9.64131 11.6739C9.64131 11.5698 9.71966 11.4829 9.82024 11.4692Z" fill="white" stroke="white" strokeWidth="0.5" />
                <path d="M17.1523 10.9674C16.9841 10.9674 16.8224 10.9514 16.6618 10.9355C16.656 10.9349 16.6501 10.9343 16.6443 10.9338C16.4862 10.9181 16.315 10.9013 16.1645 10.9133C16.0108 10.9255 15.8344 10.9707 15.7036 11.1181C15.5757 11.2622 15.5327 11.4577 15.5327 11.6739C15.5327 12.5671 16.2591 13.2935 17.1523 13.2935C18.0454 13.2935 18.7718 12.5671 18.7718 11.6739C18.7718 11.4577 18.7289 11.2622 18.601 11.1181C18.4701 10.9707 18.2937 10.9255 18.1401 10.9133C17.9895 10.9013 17.8184 10.9181 17.6602 10.9338C17.6544 10.9343 17.6486 10.9349 17.6428 10.9355C17.4821 10.9514 17.3204 10.9674 17.1523 10.9674ZM16.9458 11.6739C16.9458 11.5603 17.0385 11.4674 17.1523 11.4674C17.266 11.4674 17.3588 11.5603 17.3588 11.6739C17.3588 11.7876 17.266 11.8804 17.1523 11.8804C17.0385 11.8804 16.9458 11.7876 16.9458 11.6739Z" fill="white" stroke="white" strokeWidth="0.5" />
                <path d="M18.1354 16.7102L18.1352 16.7104C17.572 17.3396 16.8719 18.0943 16.0929 18.6917C15.3172 19.2865 14.4323 19.75 13.5001 19.75C12.5677 19.75 11.6828 19.2864 10.9072 18.6915C10.1283 18.094 9.42824 17.3394 8.86507 16.7104L8.86487 16.7102C8.6052 16.4195 8.62956 15.9732 8.91994 15.713C9.05484 15.5917 9.22473 15.5326 9.39143 15.5326C9.58409 15.5326 9.77817 15.6115 9.91783 15.7679C10.8221 16.7784 12.1277 17.3587 13.5001 17.3587C14.8725 17.3587 16.1781 16.7784 17.0824 15.7679L17.0825 15.7679C17.3424 15.4776 17.7889 15.452 18.0801 15.7128L18.1354 16.7102ZM18.1354 16.7102C18.3951 16.4194 18.3707 15.973 18.0801 15.7128L18.1354 16.7102Z" fill="white" stroke="white" strokeWidth="0.5" />
            </svg>
        );
    }
}