/*
Different type of texts in patient layout
Props:
    color = {black, red, green} default: black
    size = { large, medium, small, x-small } default: medium
    weight = { heavy, bold, light } default: bold
    title={}
*/
import React from 'react';
import PropTypes from 'prop-types';
import './label.css';

export default class Label extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const {
            size, weight, title, color, block,
        } = this.props;
        let className = `label ${size} ${weight} ${color} ${block} ${this.props.uppercase ? 'uppercase' : ''}`;
        if (this.props.className) {
            className += ' ';
            className += this.props.className;
        }
        return <span className={className}>{title || this.props.children}</span>;
    }
}

Label.propTypes = {
    color: PropTypes.string,
    size: PropTypes.string,
    weight: PropTypes.string,
    title: PropTypes.string,
    block: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
};

Label.defaultProps = {
    color: 'transparent',
    size: 'medium',
    weight: 'light',
    block: '',
    title: null,
    className: null,
    children: null,
};
