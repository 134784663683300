/*
Icons in diary entry (edit, delete)
Props:
    icon={cross= fa fa-times, pencil=fa fa-pencil}
*/

import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

export default class ButtonIcon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            ...nextProps,
        });
    }
    render() {
        const className = `fa fa-${this.props.icon}`;
        return (
            <button onClick={this.props.onClick} className={`button-icon ${this.props.className}`}>
                {this.props.icon && <i className={className} aria-hidden="true" />}{this.props.children}
            </button>
        );
    }
}

ButtonIcon.propTypes = {
    icon: PropTypes.string.isRequired,
    children: PropTypes.node,
};

ButtonIcon.defaultProps = {};
