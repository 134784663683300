import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import AssActions from '../../actions/assessments';
import { i18Get } from '../../i18';
import { Button, Label, PanelPlaceholder, Spinner } from '../../kado/components';
import { getTrans } from '../../kado/utils';
import { Options, MultipleOptions, Numeric, Text, SliderSmiley, Date, Combo, File, SliderScale, DiagnosticType } from './ass-components';
import renderHTML from 'react-render-html';
import './assessments.css';

class SingleQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            focusId: '',
        };
        this.setOverview = this.setOverview.bind(this);
        this.finishAssessment = this.finishAssessment.bind(this);
        this.nextQuestion = this.nextQuestion.bind(this);
        this.previousQuestion = this.previousQuestion.bind(this);
    }

    setOverview = () => {
        this.props.dispatch(AssActions.ass.setOverview(true));
    }
    ressetOverview = () => {
        this.props.dispatch(AssActions.ass.setOverview(false));
    }

    printPage() {
        window.print();
    }
    finishAssessment() {
        this.props.dispatch(AssActions.ass.finish(_.find(this.props.assessments.assList, 'selected').uuid, this.props.assessments.selectedAssQA.map(x => x.answer)));
    }
    previousQuestion() {
        const  selectedAssQA  = this.state.selectedAssQA;
        const nextI = _.findIndex(selectedAssQA, 'selected') - 1;
        if (nextI > -1) {
            this.props.dispatch(AssActions.ass.selectQuestion(selectedAssQA[nextI].uuid));
            this.props.dispatch(AssActions.ass.selectQuestionnaire(selectedAssQA[nextI].questionnaire.uuid));
        }
    }
    nextQuestion() {
        const  selectedAssQA  = this.state.selectedAssQA;
        const nextI = _.findIndex(selectedAssQA, 'selected') + 1;
        if (selectedAssQA.length > nextI) {
            this.props.dispatch(AssActions.ass.selectQuestion(selectedAssQA[nextI].uuid));
            this.props.dispatch(AssActions.ass.selectQuestionnaire(selectedAssQA[nextI].questionnaire.uuid));
        }
    }
    renderQuestionnaireList(ass) {
        const qList = ass.questionnaires;
        if (!_.find(qList, 'selected') && qList.length) this.props.dispatch(AssActions.ass.selectQuestionnaire(qList[0].uuid));
        return [
            qList.map(qstnr => (
                <div className={`d-inline-flex qstnr-wrapper ass-tab ${qstnr.selected ? 'selected' : ''}`} selected={qstnr.selected} uuid={qstnr.uuid} onClick={() => this.props.dispatch(AssActions.ass.selectQuestionnaire(qstnr.uuid))}>
                    {getTrans(qstnr, 'title')}
                </div>
            )),
        ];
    }

    getSubQuestions(question) {
        var subQstnArray = []
        if(question.ansType === 'o' || question.ansType === 'm') {
            question.options.map((option) => {
                if(option.section) {
                    const answer = _.find(this.props.assessments.selectedAssQA, { uuid: question.uuid });
                    if(answer.answer.option === option.uuid || (answer.answer.mOptions && typeof answer.answer.mOptions !== 'undefined' && answer.answer.mOptions.indexOf(option.uuid) !== -1) ) {
                        subQstnArray  = [...subQstnArray, ...this.renderQuestions(option.section)] 
                    }
                }
            })
        }
        return subQstnArray
    }

    renderQuestions(section) {
        var qstnsArray = []
        section.questions.map((question, index) => {
            qstnsArray.push(question);
            qstnsArray = [...qstnsArray, ...this.getSubQuestions(question)]
        })
        return qstnsArray
    }
    getQuestionList(questionnaires) {
        var totalQns = []
        questionnaires.map((q) => {
            q.sections.map((s) => {
                totalQns = [...totalQns , ...this.renderQuestions(s)]
            });
        })

        const result = _.intersectionBy(this.props.assessments.selectedAssQA, totalQns, 'uuid');
        return result
    }
    getSectionTitle(qs){
        if(qs.ansType === 'f' && qs.slider_type_question === 's'){
            return ""
        }else{
            var title =  getTrans(qs.section, 'title')
            return title;
        }
    }
    getDescriptionText(qs){
        if(qs.ansType === 'f' && qs.slider_type_question === 's'){
            return ""
        }else{
            var title =  getTrans(qs.questionnaire, 'description')
            return title;
        }
    }
    comboFunction = (uuid) => {
        this.setState({
            focusId: uuid
        })
    }
    render() {
        const language = this.props.translations.language;
        const selectedAss = _.find(this.props.assessments.assList, 'selected');
        this.state.selectedAssQA = this.getQuestionList(selectedAss.questionnaires);
        const  selectedAssQA  = this.state.selectedAssQA;
        const qi = _.findIndex(selectedAssQA, 'selected');
        if (selectedAssQA.length && qi === -1 && selectedAss && selectedAss.questionnaires) {
            const qstnr = _.find(selectedAss.questionnaires, 'selected');
            if (qstnr) {
                this.props.dispatch(AssActions.ass.selectQuestion(_.find(selectedAssQA, x => x.questionnaire.uuid === qstnr.uuid).uuid));
            }
        }
        if (qi === -1) return <PanelPlaceholder>{i18Get('Fetching')}</PanelPlaceholder>;
        const q = selectedAssQA[qi];
        const zoom = this.props.assessments.ui.zoom ? 'ass-zoom content' : 'content content-overview';
        var questionsListDivClasses = 'ass-zoom content';
        let sectionTitle= this.getSectionTitle(q);
        let descriptionText= this.getDescriptionText(q);
        let isEQ_5D_5L= q.ansType === 'f' && q.slider_type_question === 's' 
        let sliderClass = (q.ansType === 'f' && q.slider_type_question !== 's') || q.option_style === 'rs' || q.ansType === 'smiley'
        let rightAligned = q.option_style !== 's' && q.right_alignment;
        return (
            <div className="h-100" style={{position:'relative'}}>
                <div className="page-content assesment-content pt-3">
                    <div className="header pt-0">
                        <span className="heading" style={{maxWidth: '100%', display:'inline-flex'}}>
                            {this.renderQuestionnaireList(selectedAss)}
                        </span>
                    </div>

                    {q.questionnaire.background_color &&
                    <style>
                        {
                            `
                                .set-bg-color-div-single,
                                .set-bg-color-div-single .ass-qst-zoomlist .heading,
                                .set-bg-color-div-single .quest-section {
                                    background: ${q.questionnaire.background_color}
                                }
                        
                            `
                        }
                    </style>
                    }

                    <div className={`set-bg-color-div-single ${questionsListDivClasses} ${this.state.focusId === q.uuid ? 'z-top' : ''}`}>
                        {q.questionnaire.image &&
                            <div className="ml-3">
                                <img alt="" src={q.questionnaire.image} />
                            </div>
                        }
                        {(q.show_header || q.assessment_type==="p") && q.questionnaire.instruction_text && <div className="qstnre-top-help-text" >
                            {getTrans(q.questionnaire, 'instruction_text') && renderHTML(renderHTML(getTrans(q.questionnaire, 'instruction_text')))}
                        </div>}
                        <div className={`qstr-scroll ass-qst-zoom ${this.state.focusId === q.uuid ? 'overflow-visible':''}`}>
                            <div className="ass-qst-zoomlist">
                                <div className="mb-2">{descriptionText && renderHTML(descriptionText)}</div>
                                {sectionTitle &&
                                <span className="heading">
                                    {renderHTML(sectionTitle)}
                                </span>
                                }
                                <div className={`quest-section animated fadeIn d-flex ${sliderClass? "slider-clas-spl-pb2em":""} ${this.state.focusId === q.uuid ? 'z-top' : ''}`}>
                                    {q.questionnaire && q.questionnaire.show_question_order && !(isEQ_5D_5L) && <span className="heading">{this.state.assessmentType ? qi + 1 : q.order}.&nbsp;</span>} 
                                    {!q.questionnaire && <span className="heading">{this.state.assessmentType ? qi + 1 : q.order}.&nbsp;</span>}                                   
                                    <div className="ass-singleview-qst col p-0" 
                                        // style={{ minWidth: sliderClass && 520 }}
                                        >
                                        {!(isEQ_5D_5L) && <span className="heading">
                                            {getTrans(q, 'title') && renderHTML(getTrans(q, 'title'))}
                                        </span>}                                    
                                        <div className={`optionwrap ${rightAligned ? "" : ""} mt-3 ${(q.ansType === 'f' || (q.ansType === 'o' && q.option_style === 'rs')) ? 'mb-2 ml-3':''} `} style={{maxWidth:'95%'}}>
                                            {q.ansType === 'o' && q.option_style !== 'rs' && <Options question={q.uuid} options={getTrans(q, 'options')} />}
                                            {q.ansType === 'm' && <MultipleOptions question={q.uuid} options={getTrans(q, 'options')} />}
                                            {(q.ansType === 'f' && q.slider_type_question !== 's' || (q.ansType === 'o' && q.option_style === 'rs'))&& <Numeric question={q.uuid} options={getTrans(q, 'options')} />}
                                            {q.ansType === 't' && <Text question={q.uuid} options={getTrans(q, 'options')} />}

                                            {q.ansType === 'smiley' && <SliderSmiley question={q.uuid} options={getTrans(q, 'options')} />}
                                            {q.ansType === 'date' && <Date question={q.uuid} options={getTrans(q, 'options')} />}
                                            {q.ansType === 'combo' && <Combo comboFocus={this.comboFunction} key={q.uuid} question={q.uuid} options={getTrans(q, 'options')} />}
                                            {q.ansType === 'file' && <File question={q.uuid} options={getTrans(q, 'options')} />}
                                            {isEQ_5D_5L && <SliderScale question={q.uuid} options={getTrans(q, 'options')}/>}
                                            {q.ansType === 'diagnostic' && <DiagnosticType question={q.uuid} key={q.uuid} options={getTrans(q, 'options')}/>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(q.show_footer || q.assessment_type==="p") && q.questionnaire.copyright_text && <div className="qstnre-bottom-help-text my-3" >
                                {getTrans(q.questionnaire, 'copyright_text') && renderHTML(renderHTML(getTrans(q.questionnaire, 'copyright_text')))}
                        </div>}
                    </div>
                    {!selectedAss.documented && (
                        <div className="set-bg-color-div-single d-flex align-items-center page-footer" style={{ bottom: 0 }}>
                            <Button className="button-primary" disabled={this.props.assessments.isFinishAssessmentCompleted === 'PENDING'} onClick={this.finishAssessment}>
                                {this.props.assessments.isFinishAssessmentCompleted === 'PENDING' && <Spinner />} {i18Get('Finish')}
                            </Button>
                            <div className="btn-wrap group-btn ml-3">
                                <Button className={qi === 0 ? 'button-inactive' : ''} onClick={this.previousQuestion}>
                                    <span className="d-none d-sm-block">{i18Get('Previous')}</span>
                                    <span title={i18Get('Previous')} className="d-block d-sm-none">{i18Get('<')}</span>
                                </Button>
                                <Button className={qi + 1 === this.state.selectedAssQA.length ? 'button-inactive' : ''} onClick={this.nextQuestion.bind(this, q)}>
                                    <span className="d-none d-sm-block">{i18Get('Next')}</span>
                                    <span title={i18Get('Next')} className="d-block d-sm-none">{i18Get('>')}</span>
                                </Button>
                            </div>

                            <div className="ml-auto">
                                <b>{qi + 1}{'/'}{this.state.selectedAssQA.length}</b>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
SingleQuestion.propTypes = {};

export default connect(store => ({
    assessments: store.assessments,
    translations: store.translations,
}))(SingleQuestion);
